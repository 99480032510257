import React, { useState, useEffect } from 'react';

import useMediaQuery from 'use-media-antd-query';
import { Drawer, Form, Select, Button, Col, Row, Input, Modal, Upload, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import { ACCESS_TOKEN, API_BASE_URL, GET_USERS_FILES_CTR, STATUS_CODE_REST_API } from 'constant';
import { exeRequestFinal, exeRequest } from 'util/APIUtils';
import axios from 'axios';
import { getUserAsaci } from 'services/user';

const { Option } = Select;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function UpdateForm(props) {
  const { currentItem } = props

  const [state, setState] = useState({
    loading: false,
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [{
      url: GET_USERS_FILES_CTR + currentItem.username + ".png",
      uid: props.currentItem.id,
      name: 'image.png',
      status: 'done'
    }],
    roles: [],
    isUpdateDrawerVisible: false,
    roleLoad: false,
    currentUser: {},
    listUsers: []
  })

  const [form] = Form.useForm();

  useEffect(() => {
    setState(state => ({ ...state, isLoading: true }));
    getAllRole()
    getAllUsers()
  },
    [])

  function handleClose(user) {
    props.close(user);
  }

  function handleCloseUpdate() {
    setState(state => ({ ...state, isUpdateDrawerVisible: false }))
  }

  // Notification
  const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
      message: title,
      description: msg,
    });
  };


  // Get all user
  const getAllUsers = values => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/users',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(response => {
        setState(state => ({
          ...state,
          listUsers: response.data.data,
          roleLoad: true,
        }))
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onFinish = (values) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'post',
      url: API_BASE_URL + '/users/' + currentItem.id,
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
      data: values,
    };
    // alert(JSON.stringify(values))
    axios(config)
      .then(response => {
        console.log("reponse create user: ", response.data)
        setState(state => ({ ...state, response: state.listUsers }))
        openNotificationWithIcon('success', 'Succès', 'Modification effectuée avec succès !!!');
        handleClose()
        getAllUsers()
      })
      .catch(function (error) {
        openNotificationWithIcon('error', 'Erreur', 'Une erreur est survenue lors de la modification !!!');
        handleClose()
        getAllUsers()
      });
  }

  const colSize = useMediaQuery();

  const getAllRole = values => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/role',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(response => {
        setState(state => ({
          ...state,
          roles: response.data,
          roleLoad: true,
        }))
        console.log("reponse get role update: ", response.data)

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Drawer
        title={`${currentItem.username}`}
        width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 720}
        onClose={() => handleCloseUpdate()}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >

        <Form layout="vertical" hideRequiredMark form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item name="id" initialValue={currentItem.id}>
            <Input type="hidden" />
          </Form.Item>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="codeCompagnie"
                label="Code compagnie"
                rules={[{ required: true, message: 'Veuillez saisir le code' }]}
                initialValue={currentItem.codeCompagnie}
              >
                <Input placeholder="Veuillez saisir le code compagnie" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="nomPrenom"
                label="Nom compagnie"
                rules={[{ required: true, message: 'Veuillez saisir le login' }]}
                initialValue={currentItem.nomPrenom}
              >
                <Input placeholder="Veuillez saisir le login" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="username"
                label="Nom utilisateur"
                rules={[{ required: true, message: 'Veuillez saisir le Nom utilisateur' }]}
                initialValue={currentItem.username}
              >
                <Input placeholder="Veuillez saisir le login" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="phone"
                label="Téléphone"
                rules={[{ required: true, message: 'Veuillez saisir le téléphone' }]}
                initialValue={currentItem.phone}
              >
                <Input
                  style={{ width: '100%' }}
                  addonBefore="+225"
                  placeholder="Veuillez saisir le téléphone"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { required: true, message: "Veuillez saisir l'email" },
                  { type: 'email', message: "Votre email valide" },
                ]}
                initialValue={currentItem.email}
              >
                <Input placeholder="Veuillez saisir l'email" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="raisonSocial"
                label="Raison sociale"
                rules={[{ required: true, message: 'Veuillez saisir la raison sociale' }]}
                initialValue={currentItem.raisonSocial}
              >
                <Input placeholder="Veuillez saisir la raison sociale" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="roleId"
                label="Role"
                rules={[{ required: true, message: 'Veuillez selectionner le role' }]}
                initialValue={currentItem.name}
              >
                <Select placeholder="Veuillez selectionner le role">
                  {state.roles.map((m, index) => (
                    <Option key={m.id} value={m.id}>{m.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Nouveau mot de passe"
                name="password"
                rules={[{ required: true, message: 'Veuillez saisir le nouveau mot de passe' }]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="confirm"
                label="Confirmez le mot de passe"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Veuillez repeter le mot de passe',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject('Les deux mots de passe ne correspondent pas');
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => handleClose()} style={{ marginRight: 8 }}>
              Annuler
            </Button>
            <Button
              loading={state.loading}
              htmlType="submit" type="primary"
            >
              Enregistrer
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}

export default UpdateForm