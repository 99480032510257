import React, { Component } from 'react';
import { Input, message, Upload, Table, Card, Spin, Button, notification } from "antd";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import http from "http"
import https from "https"
import axios from 'axios';
//import * as urlApi from "../urlApi";
import swal from 'sweetalert';
import Swal from 'sweetalert2';

import { ACCESS_TOKEN, API_BASE_URL } from 'constant';

const api_upload = API_BASE_URL + '/files/upload';


const { Dragger } = Upload;

// Notification
const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
        message: title,
        description: msg,
    });
};

class UploadStep1 extends Component {
    constructor(props) {
        super(props);
        let data = []
        try {
            data = this.props.parent.state.fileUpload.fileExcel
        } catch (e) {
            console.log(e)
        }
        this.state = {
            loading: false,
            datasource: data,
            isLoading: false,
            uploading: false,
            error: null,
            searchedColumn: '',
            spin: true,
            file: "",
            listSinistre: [],

            uplloadErrors: []
        };
    }

    FileChangeHandler = (e) => {
        e.preventDefault();
        console.log(e)
        this.setState({
            uploading: true
        });

        this.props.toggleBackdrop();

        let token = localStorage.getItem(ACCESS_TOKEN);
        let formData = new FormData();
        formData.append('file', e.target.files[0]);

        const httpAgent = new http.Agent({ keepAlive: true })
        const httpsAgent = new https.Agent({ keepAlive: true })

        // var config = {
        //     method: 'post',
        //     url: api_upload,
        //     timeout: 360000,
        //     headers: {
        //         "Accept": "application/json",
        //         //"Content-Type": "multipart/form-data",
        //         "Authorization": 'Bearer ' + token
        //     },
        //     httpAgent,
        //     httpsAgent,
        //     // httpAgent: new http.Agent({ keepAlive: true }),
        //     data: formData
        // };

        // axios(config)
        //     .then(response => {
        //         swal({
        //             title: "Chargement terminé !!!",
        //             text: "Chargement effectué avec succès",
        //             icon: "success",
        //             closeOnClickOutside: false,
        //         }).then(x => (
        //             window.location.href = "/reclamationEffectuees"
        //             //  window.location.reload(),
        //             //this.getAllSinistre()
        //         ))
        //         console.log(response)
        //         this.setState({ isLoading: false })
        //         // console.log("reponse add file: ", response.data)
        //         // this.getAllSinistre()
        //     })
        //     .catch((error) => {
        //         console.log(error)
        //         this.props.toggleBackdrop();
        //         this.setState({
        //             datasource: [],
        //             uploading: false
        //         });
        //         swal({
        //             title: "Erreur",
        //             text: error.message,
        //             icon: "warning",
        //             closeOnClickOutside: false,
        //         })
        //     });

        const api = axios.create({
            baseURL: API_BASE_URL,
            timeout: 360000,
            headers: {
                "Accept": "application/json",
                //"Content-Type": "multipart/form-data",
                "Authorization": 'Bearer ' + token
            },
            httpAgent,
            httpsAgent,
            // httpAgent: new http.Agent({ keepAlive: true }),
            data: formData
        })

        api.interceptors.response.use(
            response => response, // Laissez passer les réponses réussies
            error => {
              if (error.response ) {
                // Gérez la réponse 400 ici
                const errorResp = error?.response?.data;
                console.error('Erreur 400 :', errorResp);
                //this.setState({uplloadErrors: errorResp?.data});
                // Vous pouvez afficher un message d'erreur, etc.
                Swal.fire({
                    title: errorResp && errorResp?.message ? errorResp?.message : "Une erreur est survenue, veuillez réessayer plus tard",
                    //text: `${errorResp?.data[0]}`,
                    html: errorResp?.data ? `<div style="height: 100px; color:red">${(errorResp?.data || []).join('<br/>')}</div>` : '',
                    icon: "warning",
                    closeOnClickOutside: false,
                    
                })
              }
              return Promise.reject(error);
            }
          );

        api.post('/files/upload', formData )
        .then(response=> {
            swal({
                title: "Chargement terminé !!!",
                text: "Chargement effectué avec succès",
                icon: "success",
                closeOnClickOutside: false,
            }).then(x => (
                window.location.href = "/reclamationEffectuees"
                //  window.location.reload(),
                //this.getAllSinistre()
            ))
            console.log(response)
            this.setState({ isLoading: false })
        })
        .catch((error) => {
            console.log(error)
            this.props.toggleBackdrop();
            this.setState({
                datasource: [],
                uploading: false
            });
            
        });

        setTimeout(() => {
            window.location.href = "/reclamationEffectuees";
        }, 90000)

    };

    componentDidMount() {
        this.getAllSinistre()
    }

    // validate = ()=>{
    //     if (this.state.datasource.length<1){
    //         message.error("Aucune ligne d'attestation !");
    //     }else this.props.parent.next()
    // }

    validate = () => {
        let data = this.props.parent.state.fileUpload
        let token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'post',
            headers: {
                "Accept": "application/json",
                //"Content-Type": "multipart/form-data",
                "Authorization": 'Bearer ' + token
            },
        };
        axios.post(api_upload, config).then(response => {
            if (response.data.statusCode === 10) {
                swal({
                    title: "Enregistrement effectué ",
                    text: "Flotte créée avec succès",
                    icon: "success",
                    closeOnClickOutside: false,
                }).then(x => (
                    window.location.href = "/reclamationEffectuees"
                ))
            } else {
                swal({
                    title: "Enregistrement echoué ",
                    text: response.data.message,
                    icon: "warning",
                    closeOnClickOutside: false,
                })
            }

        })
            .catch(error => {

                swal({
                    title: "Erreur",
                    text: error.message,
                    icon: "warning",
                    closeOnClickOutside: false,
                })
            });

    }

    getAllSinistre = values => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/sinistre/attente',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                console.log("reponse get sinistre attente qq====================>>>>>", response)
                //    let newArticle = response.data
                this.setState({
                    listSinistre: response.data.data,
                    sinistreLoad: true,
                })
                console.log("reponse get sinistre attente====================>>>>>", response)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        let { datasource, uploading } = this.state;
        const props = {
            name: 'file',
            action: api_upload,
            multiple: false,
            headers: {
                authorization: 'authorization-text',
            },

            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);

                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        return (
            <div>
                <form className="pt-3 pb-3">
                    <div className=" p-4">
                        <Input type={"file"} name={"file"} onChange={this.FileChangeHandler} style={{ cursor: "pointer" }}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel" />
                    </div>
                    {/* <Upload onChange={this.FileChangeHandler} type={"file"} name={"file"} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
                        <Button  icon={<UploadOutlined />}>Importer le fichier Excel</Button>
                    </Upload> */}
                </form>
                {/* <div className="w-100 p-4">
                    <div className="w-100 " style={{overflow:"scroll"}}>
                        {uploading?(
                            <Spin/>
                        ):(
                            <Table key={"mexcel"} columns={columns} dataSource={datasource} size="small" />
                        )}
                    </div>
                </div> */}
                <br />
                <br />
                {/* <button type="button" className="float-right btn btn-primary mr-3" onClick={() => this.validate()}>
                    Enregistrer
                </button> */}
            </div>
        );
    }
}
export default UploadStep1;
