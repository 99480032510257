import React, { Fragment, useState, useEffect, useRef } from 'react';

import { connect } from "react-redux";
import makeStyles from '@material-ui/styles/makeStyles';
import { Card, DatePicker, Table, Input, Button, Space, Divider, Spin, Select, Col, Form, Row, Tag } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from "react-csv";
import axios from 'axios';
import Modal from 'antd/lib/modal/Modal';
import { ACCESS_TOKEN, API_BASE_URL } from 'constant';
import useHandleSearch from 'hooks/useHandleSearch'


const { Option } = Select;
const { confirm } = Modal;
const { RangePicker } = DatePicker;
const useStyles = makeStyles(theme => ({
  loginBtn1: {
    textAlign: 'right',
    float: 'right',
    display: 'inline-block',
    marginTop: "-15px",
    popvisible: false,
    updateLigne: [],
  },

  loginBtn2: {
    textAlign: 'left',
    float: 'right',
    display: 'inline-block',
    paddingRight: "9px",
    marginTop: "-15px",
  }
}));

const key = 'updatable';

function Reporting(props) {
  const { hasRole } = props
  const searchInput = useRef()

  const [state, setState] = useState({
    Liste: [],
    inventaireLoad: false,
    endDate: null,
    startDate: null,
    isLoading: false,
    exporteResult: {
      rcode: "",
      rmsg: "",
      rurlpdf: "",
      rurlexcel: "",
    },
    selectStatut: "",
    selectCompagnie: "",
    listCompagnie: [],
    listSinistreReporting: [],
    isTableLoading: true
  })

  const [page, setPage] = useState(1);

  const classes = useStyles()
  useEffect(() => {
    // generateReport();
    getAllCompagnie();
    getAllSinistre()
    // renderTable()
  },
    [])


  const getAllCompagnie = values => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/users/compagnie',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(response => {
        // console.log("compagnie =========================>>>>>>>>>>>>>>>>>>", response)
        setState(state => ({
          ...state,
          inventaireLoad: true,
          listCompagnie: response.data.data,

        }))
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getAllSinistre = values => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/sinistre/liste',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(response => {
        // console.log("compagnie =========================>>>>>>>>>>>>>>>>>>", response)
        setState(state => ({
          ...state,
          isTableLoading: false,
          listSinistreReporting: response.data.data,
        }))
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleSelectChange(selectStatut) {
    setState(state => ({ ...state, selectStatut: selectStatut }))
    // console.log("select satut", selectStatut)
    return selectStatut;
  }

  function handleSelectChangeCompagnie(selectCompagnie) {
    setState(state => ({ ...state, selectCompagnie: selectCompagnie }))
    return selectCompagnie;
  }

  function generateReport(values) {
    setState(state => ({ ...state, isLoading: true }))
    let newStartDate = null;
    let newEndDate = null;
    let selectStatut = state.selectStatut;
    let selectCompagnie = state.selectCompagnie;

    if (state.startDate === null && state.endDate === null) {
      newStartDate = '0000-00-00'//new Date().toISOString().slice(0, 10)
      newEndDate = '0000-00-00' //new Date().toISOString().slice(0, 10)
    }
    else {
      newStartDate = state.startDate;
      newEndDate = state.endDate;
    }
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      // url: API_BASE_URL + '/reports/sinistre?dateDebut=' + newStartDate + '&&dateFin=' + newEndDate + '&&statut=' + selectStatut + '&&userId=' + selectCompagnie,
      url: API_BASE_URL + '/reports/sinistre/excel?code=' + selectCompagnie + '&&statut=' + selectStatut,
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    // alert(JSON.stringify(selectCompagnie))
    axios(config)
      .then(response => {
        window.open(config.url)
        setState(state => ({
          ...state,
          isLoading: true,
        }))
        getAllSinistre()
        getAllCompagnie()
        window.location.reload()
        // openMessage()
        // console.log("list reporting: ", response.data)

      })
      .catch(function (error) {
        console.log(error);
        setState(state => ({
          ...state,
          isLoading: false,
        }))
      });
  }


  const getColumnSearchProps = useHandleSearch()

  const columns = [
    {
      title: 'Date action',
      // width: 200, 
      key: '1',
      dataIndex: "updatedAt",
    },
    {
      title: 'Compagnie adverse',
      // width: 190,
      key: 'compagnieAdverse',
      dataIndex: "compagnieAdverse",
      ...getColumnSearchProps('compagnieAdverse'),
    },
    {
      title: 'Code compagnie adverse',
      // width: 190,
      key: 'codeAdverse',
      dataIndex: "codeAdverse",
      ...getColumnSearchProps('codeAdverse'),
    },
    {
      title: 'Nom assuré',
      // width: 260, 
      key: 'nomAssureAdverse',
      dataIndex: "nomAssureAdverse",
      ...getColumnSearchProps('nomAssureAdverse'),
    },
    {
      title: 'Montant',
      // width: 260, 
      key: 'montantRecours',
      dataIndex: "montantRecours",
      ...getColumnSearchProps('montantRecours'),
    },
    {
      title: 'Statut',
      key: 'statut',
      render: (item) => (
        <div>
          {item?.statut == 1 ? <Tag color="blue">Attente</Tag> : item?.statut == 2 ? <Tag color="green">Regler</Tag> : <Tag color="red">Contester</Tag>}
        </div>
      )
    },
  ];

  // Column admin
  const columnsAdmin = [
    {
      title: 'Date creation',
      // width: 200, 
      key: '1',
      dataIndex: "createdAt",
    },
    {
      title: 'Code compagnie beneficiaire',
      // width: 200, 
      key: 'code',
      dataIndex: "code",
      ...getColumnSearchProps('code'),
    },
    {
      title: 'Code compagnie adverse',
      // width: 190,
      key: 'codeAdverse',
      dataIndex: "codeAdverse",
      ...getColumnSearchProps('codeAdverse'),
    },
    {
      title: 'Nom assuré',
      // width: 260, 
      key: 'nomAssureAdverse',
      dataIndex: "nomAssureAdverse",
      ...getColumnSearchProps('nomAssureAdverse'),
    },
    {
      title: 'Montant',
      // width: 260, 
      key: 'montantRecours',
      dataIndex: "montantRecours",
      ...getColumnSearchProps('montantRecours'),
    },
    {
      title: 'Statut',
      key: 'statut',
      render: (item) => (
        <div>
          {item?.statut == 1 ? <Tag color="blue">Attente</Tag> : item?.statut == 2 ? <Tag color="green">Regler</Tag> : <Tag color="red">Contester</Tag>}
        </div>
      )
    },
  ];

  function startDateOnChange(value, dateString) {
    setState(state => ({ ...state, startDate: dateString }))
  }

  function endDateOnChange(value, dateString) {
    setState(state => ({ ...state, endDate: dateString }))
  }

  let { isLoading, Liste, exporteResult } = state
  let JSONThead = [
    { label: "Date", key: "code" },
    { label: "Article", key: "name" },
    { label: "Numero serie", key: "numeroSerie" },
    { label: "Qte entrée", key: "qteInit" },
    { label: "Qte sortie", key: "qteSortie" },
    { label: "Qte finale", key: "qteStock" }
  ]


  const { article } = state

  return (
    <Fragment>
      <Card>
        <div className="container-fluid">
          <div>
            <div className="float-left">
              <h4>Reporting</h4>
            </div>
            {/* <div className="float-right">
                            <CSVLink data={Liste} headers={JSONThead}  filename={"saco-export-reporting.csv"} separator={";"} >
                              <Button type="primary">
                                  <DownloadOutlined /> Exporter en CSV
                              </Button>
                            </CSVLink>
                        </div> */}
          </div><br /><br /><br />
          <div>
            {/* <div className="float-right">
                            <Input placeholder="Recherche"/>
                        </div> */}

          </div><br />

        </div><br /><br />
        <Form layout="vertical" hideRequiredMark >
          <Row gutter={24}>
            {/* <Col span={5}>
              <Form.Item label="Date début">
                <DatePicker placeholder="Date debut" className="w-100" format={"YYYY-MM-DD"} onChange={startDateOnChange} />,
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Date fin">
                <DatePicker placeholder="Date fin" className="w-100" format={"YYYY-MM-DD"} onChange={endDateOnChange} />,
              </Form.Item>
            </Col> */}
            {(localStorage.getItem('role') === "ROLE_ADMIN") ? (
              <Col span={10}>
                <Form.Item
                  name="nomPrenom"
                  label="Compagnie"
                  rules={[{ required: true, message: "Veuillez selectionner la compagnie" }]}
                >
                  <Select placeholder="Veuillez selectionner la compagnie" onChange={handleSelectChangeCompagnie}>
                    {state.listCompagnie.map((m, index) => (
                      <Option key={index} value={m.nomPrenom}>{m.nomPrenom}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : (null)}

            <Col span={10}>
              <Form.Item
                name="statut"
                label="Statut"
                rules={[{ required: true, message: "Selectionner le type d'opération" }]}
              >
                <Select placeholder="Selectionner le type d'opération" onChange={handleSelectChange}>
                  <Option value="1">Attente</Option>
                  <Option value="2">Regler</Option>
                  <Option value="3">Contester</Option>
                  <Option value="4">Récapitulatif</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} style={{ marginTop: 30 }}>
              <Button type="primary" loading={state.isLoading} onClick={() => { generateReport() }}>Generer</Button>
              {/* ; setState(state=>({...state, isLoading: true})) */}
            </Col>
          </Row>
        </Form>

        <Divider />

        {(localStorage.getItem('role') === "ROLE_COMPAGNIE") ? (
          <>
            <div>
              {state.inventaireLoad ? (
                <Table loading={state.isTableLoading} dataSource={state.listSinistreReporting} columns={columns} />
              ) : (<Spin />)}
            </div>
          </>
        ) : (
          <>
            <div>
              {state.inventaireLoad ? (
                <Table loading={state.isTableLoading} dataSource={state.listSinistreReporting} columns={columnsAdmin} />
              ) : (<Spin />)}
            </div>
          </>
        )}

      </Card>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    hasRole: state.auth.hasRole
  }
}

export default connect(mapStateToProps)(Reporting);
