import {ADD_TO_CART } from "../actionTypes";

const cartReducer = (state = [], action) => {
    switch (action.type) {
        case ADD_TO_CART:
            console.log("cart 1111111111111", state)
            return [...state, action.value]
        default:
            return state;
    }
}

export default cartReducer;