import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, Card, Col, Divider, Drawer, Form, Input, message, notification, Row, Select, Spin, Table, Tabs, Tag } from 'antd';
import 'antd/dist/antd.css';
import Modal from 'antd/lib/modal/Modal';
import axios from 'axios';
import { ACCESS_TOKEN, API_BASE_URL, CURRENT_USER } from 'constant';
import AddForm from 'components/layout/Dashbord/AddForm';
import UploadStep1 from 'pages/Importation/UploadStep1';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import useMediaQuery from 'use-media-antd-query';
import useHandleSearch from 'hooks/useHandleSearch'


// import { Drawer, List, Avatar, Divider, Col, Row } from 'antd';


const api_upload = API_BASE_URL + '/files/upload';

const { Option } = Select;
const { confirm } = Modal;


const useStyles = makeStyles(theme => ({
    loginBtn1: {
        textAlign: 'right',
        float: 'right',
        display: 'inline-block',
        marginTop: "-15px",
        popvisible: false,
        updateLigne: [],

    },

    loginBtn2: {
        textAlign: 'left',
        float: 'right',
        display: 'inline-block',
        paddingRight: "9px",
        marginTop: "-15px",
    }
}));
const pageSize = 10
const key = 'updatable';
const { TabPane } = Tabs;

function AgenceList(props) {
    const { hasRole } = props
    const searchInput = useRef()
    const [form] = Form.useForm();

    const [state, setState] = useState({
        isLoading: false,
        visible: false,
        listContestationCompagnie: [],
        listContestationAdmin: [],
        inputValue: "",
        searchInput: "",
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
        listContestationAdverse: [],
        modal2Visible: false,
        datasource: [],
        file: {},
        operations: "",
        value: 'value',
        currentData: {},
        sinistreLoad: false,
        isAddDrawerVisible: false,
        selectValue: "",
        listUser: []
    })

    useEffect(() => {
        getAllContestation();
    }, [])

    const listSinistre = state;



    const getAllContestation = values => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/sinistre/contestation',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                setState(state => ({
                    ...state,
                    sinistreLoad: true,
                    listContestationCompagnie: response.data.data?.contestationCompagnie,
                    listContestationAdverse: response.data.data?.contestationAdverse,
                    listContestationAdmin: response.data.data?.contestationAdmin,
                }))
                console.log("test =====================================", response.data.data?.contestationAdmin)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // ColumnAdmin
    const columnsAdmin = [
        {
            title: 'Date validation',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.createdAt}
                </div>
            )
        },
        {
            title: 'Compagnie',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.nomCompagnie}
                </div>
            )
        },
        {
            title: 'Type opération',
            width: 400,
            key: 'typeRegler',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.typeRegler}
                </div>
            ),

        },
        {
            title: 'Statut',
            key: 'statut',
            width: 400,
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.statut == 2 ? <Tag color="green">Regler</Tag> : <Tag color="red">Contester</Tag>}
                </div>
            )
        },
    ];

    // Colonnes des sinistre de la compagnie declarante
    const columns = [
        {
            title: 'Date validation',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.createdAt}
                </div>
            )
        },
        {
            title: 'Compagnie',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.nomCompagnie}
                </div>
            )
        },
        {
            title: 'Type opération',
            width: 400,
            key: 'typeRegler',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.typeRegler}
                </div>
            ),

        },
        {
            title: 'Statut',
            key: 'statut',
            width: 400,
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.statut == 2 ? <Tag color="green">Regler</Tag> : <Tag color="red">Contester</Tag>}
                </div>
            )
        },
    ];

    // Colone de la compagnie adverse
    const columns1 = [
        {
            title: 'Date validation',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.createdAt}
                </div>
            )
        },
        {
            title: 'Compagnie',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.nomCompagnieAdverse}
                </div>
            )
        },
        {
            title: 'Type opération',
            width: 400,
            key: 'typeRegler',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.typeRegler}
                </div>
            ),

        },
        {
            title: 'Statut',
            key: 'statut',
            width: 400,
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.statut == 2 ? <Tag color="green">Regler</Tag> : <Tag color="red">Contester</Tag>}
                </div>
            )
        },
    ];

    //============================= Search sur la table des sinistre declarant ================================

    // Notification
    const openNotificationWithIcon = (type, title, msg) => {
        notification[type]({
            message: title,
            description: msg,
        });
    };


    // Checkbook
    // console.log("log file" , state.file) // Console pour la selection du fichier

    const start = (e) => {
        if (!state.operations || isNaN(state.operations)) {
            message.warning("Veuillez selectionner le type d'opération !!!");
            return false;
        }
        let infoValue = state.operations
        setState(state => ({ ...state, loading: true }));
        const token = localStorage.getItem(ACCESS_TOKEN);
        const validationRequest = {
            listId: selectedRowKeys,
            statut: infoValue
        }
        const json = JSON.stringify(validationRequest);
        const blob = new Blob([json], {
            type: "application/json"
        })
        let formData = new FormData();
        formData.append('file', (state.file != null ? state.file : ""));
        formData.append('validationRequest', blob);

        setState(state => ({
            ...state,
            selectedRowKeys: [],
            loading: false,
        }));

        const url = API_BASE_URL + '/sinistre/validation';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": 'Bearer ' + token
            },
            data: formData
        };
        axios(url, options)
            .then(response => {
                setState(state => ({ ...state, isLoading: false }))
                openNotificationWithIcon('success', 'Succès', 'Operartion effectuée avec succès !!!')
                setModal2Visible(false)
                setState(state => ({ ...state, }))
                getAllContestation()
                window.location.reload()
            })
            .catch(function (error) {
                // openNotificationWithIcon('error','Erreur','Erreur cote client !!!');
                console.log(error);
            })
    };

    const onSelectChange = (selectedRowKeys) => {
        setState(state => ({ ...state, selectedRowKeys }));
    };

    const { loading, selectedRowKeys } = state;

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    // Function validation 

    function setModal2Visible(modal2Visible) {
        setState(state => ({ ...state, modal2Visible }));
    }

    function handleSelectChange(selectValue) {
        setState(state => ({ ...state, operations: selectValue }))
        return selectValue;
    }

    const showDrawer = (sinistre) => {
        setState(state => ({
            ...state,
            visible: true,
            currentData: sinistre
        }));
    };

    const onClose = () => {
        setState(state => ({
            ...state,
            visible: false,
        }));
    };

    function handleAddClose() {
        setState(state => ({ ...state, isAddDrawerVisible: false }))
    }

    const colSize = useMediaQuery();
    const getColumnSearchProps = useHandleSearch()


    const expandedRowRender = (donnees) => {
        const columns = [
            {
                title: 'Date sinistre',
                width: 90,
                key: 'dateSinistre',
                dataIndex: "dateSinistre",
            },
            {
                title: 'Numero police',
                width: 100,
                key: 'numeroPoliceAdverse',
                dataIndex: "numeroPoliceAdverse",
            },
            {
                title: 'Code compagnie',
                width: 90,
                key: 'codeAdverse',
                dataIndex: "codeAdverse",
                ...getColumnSearchProps('codeAdverse'),
            },
            {
                title: 'Immatriculation',
                width: 90,
                key: 'numeroImmatriculationAssureAdverse',
                dataIndex: "numeroImmatriculationAssureAdverse",
            },
            {
                title: 'Nom assuré',
                width: 100,
                key: 'nomAssureAdverse',
                dataIndex: "nomAssureAdverse",
                ...getColumnSearchProps('nomAssureAdverse'),
            },
            // {
            //     title: 'Type recours',
            //     width: 100,
            //     key: 'typeRecours',
            //     dataIndex: "typeRecours",
            // },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, reglement) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(reglement)} />
                    </>
                ),
            },

        ];

        return <Table columns={columns} scroll={{ x: 1000 }} dataSource={donnees.listSinistre} pagination={false} />;
    };

    return (
        <Fragment>
            <Card>
                <div className="container-fluid">
                    <h2>Liste des contestations</h2><br />
                </div>

                <Divider />

                {(localStorage.getItem('role') === "ROLE_COMPAGNIE") ? (
                    <>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Contestation émis" key="1">
                                <br />
                                {state.sinistreLoad ? (
                                    <Table
                                        columns={columns}
                                        dataSource={state.listContestationCompagnie.map(item => ({ ...item, key: item.validationSinistre?.id }))}
                                        expandable={{ expandedRowRender }}
                                    />
                                ) : (<Spin />)}
                            </TabPane>
                            <TabPane tab="Contestation reçu" key="2">
                                {state.sinistreLoad ? (
                                    <Table
                                        columns={columns1}
                                        dataSource={state.listContestationAdverse.map(item => ({ ...item, key: item.validationSinistre?.id }))}
                                        // scroll={{ x: 1800}}
                                        expandable={{ expandedRowRender }}
                                    />
                                ) : (<Spin />)}

                            </TabPane>
                        </Tabs>
                    </>
                ) : (
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Contestations" key="1">
                            <br />
                            {state.sinistreLoad ? (
                                <Table
                                    columns={columnsAdmin}
                                    dataSource={state.listContestationAdmin.map(item => ({ ...item, key: item.validationSinistre?.id }))}
                                    expandable={{ expandedRowRender }}
                                />
                            ) : (<Spin />)}
                        </TabPane>
                    </Tabs>
                )}

            </Card>

            {/* =================================================== VALIDATION DE L'ASSURANCE =============================================== */}

            <Modal
                title="ATTENTION !!!"
                centered
                visible={state.modal2Visible}
                onOk={() => start()}
                onCancel={() => setModal2Visible(false)}
                confirmLoading={state.loading}
            >
                <div>
                    <Form>
                        <Form.Item rules={[{ required: true }]}>
                            <Select defaultValue="Selectionner le type d'operation" onChange={handleSelectChange}>
                                <Option value={"2"}>Regler</Option>
                                <Option value={"3"}>Contester</Option>
                            </Select>
                        </Form.Item>
                    </Form>,
                    <div>
                        <input type="file" onChange={(e) => { setState(state => ({ ...state, file: e.target.files[0] })) }} />
                    </div>
                </div>
            </Modal>

            {/* ====================================================== Drawer  onChange={} =========================================================== */}

            <Drawer
                //   width={900}
                width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 900}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={state.visible}
            >
                <h4 className="site-description-item-profile-p">Details</h4>
                <Divider />
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <div style={styles.itemContainerInfos}>
                            <h5>Informations de la compagnie declarante</h5>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={styles.itemContainer}>
                            <span>Code</span> : <span style={{ ...styles.itemValue }}>{state.currentData?.code}</span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={styles.itemContainer}>
                            <span>Date sinistre</span> : <span style={styles.itemValue}>{state.currentData?.dateSinistre}</span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={styles.itemContainer}>
                            <span>Numéro police</span> : <span style={styles.itemValue}>{state.currentData?.numeroPolice}</span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={styles.itemContainer}>
                            <span>Nom assuré</span> : <span style={{ ...styles.itemValue }}>{state.currentData?.nomAssure}</span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={styles.itemContainer}>
                            <span>Immatriculation assuré</span> : <span style={styles.itemValue}>{state.currentData?.numeroImmatriculationAssure}</span>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div style={styles.itemContainer}>
                            <span>Numéro sinistre</span> : <span style={styles.itemValue}>{state.currentData?.numeroSinistre}</span>
                        </div>
                    </Col>
                    {/* <Col span={8}>
                    <div style={styles.itemContainer}>
                        <span>Index de ligne</span> : <span style={styles.itemValue}>{state.currentData?.indexLigne}</span>
                    </div>
                </Col>
                <Col span={8}>
                    <div style={styles.itemContainer}>
                        <span>Index</span> : <span style={styles.itemValue}>{state.currentData?.index}</span>
                    </div>
                </Col> */}
                </Row>
                <Divider />
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <div style={styles.itemContainerInfos}>
                            <h5>Informations de la compagnie adverse</h5>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Code</span> : <span style={styles.itemValue}>{state.currentData?.codeAdverse}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Compagnie</span> : <span style={styles.itemValue}>{state.currentData?.compagnieAdverse}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Numéro Police</span> : <span style={styles.itemValue}>{state.currentData?.numeroPoliceAdverse}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Nom assuré</span> : <span style={styles.itemValue}>{state.currentData?.nomAssureAdverse}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Immatriculation</span> : <span style={styles.itemValue}>{state.currentData?.numeroImmatriculationAssureAdverse}</span>
                        </div>
                    </Col>
                    {/* <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Type recours</span> : <span style={styles.itemValue}>{state.currentData?.typeRecours}</span>
                        </div>
                    </Col> */}
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Motif de validation</span> : <span style={styles.itemValue}>{state.currentData?.motifValidation}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Date de validation</span> : <span style={styles.itemValue}>{state.currentData?.dateValidation}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Montant recours</span> : <span style={styles.itemValue}>{state.currentData?.montantRecours} FCFA</span>
                        </div>
                    </Col>
                    {/* <Col span={8}>
                    <div style={styles.itemContainer}>
                        <span>Année1</span> : <span style={styles.itemValue}>{state.currentData?.annee1}</span>
                    </div>
                </Col>
                <Col span={8}>
                    <div style={styles.itemContainer}>
                        <span>Année2</span> : <span style={styles.itemValue}>{state.currentData?.annee2}</span>
                    </div>
                </Col> */}
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Selection</span> : <span style={styles.itemValue}>{state.currentData?.selection}</span>
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <div style={styles.itemContainerInfos}>
                            <h5>Informations payement</h5>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Montant recours</span> : <span style={styles.itemValue}>{state.currentData?.montantRecours}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Montant payé</span> : <span style={styles.itemValue}>{state.currentData?.montantPaye}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Montant restant</span> : <span style={styles.itemValue}>{state.currentData?.montantRest}</span>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={styles.itemContainer}>
                            <span>Statut payement</span> : <span style={styles.itemValue}>{state.currentData?.statutPaiement}</span>
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    {state.currentData.fileName ? (
                        <Col className="gutter-row" span={8}>
                            <span style={{ cursor: "pointer", color: '#1E88E5' }} onClick={(e) => window.open(API_BASE_URL + "/files/downloadFile/" + state.currentData.fileName)} ><i className="fa fa-file"></i>Télécharger le fichier</span>{"    "}
                        </Col>
                    ) : (null)}

                </Row><br /><br />

                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
                        Fermer
                    </Button>
                </div>
            </Drawer>

            {state.isAddDrawerVisible && (
                <AddForm
                    visible={state.isAddDrawerVisible}
                    close={handleAddClose}
                />
            )}

        </Fragment>
    )
}

const styles = {
    itemContainer: {
        margin: 7,
        fontSize: 15
    },
    itemContainerInfos: {
        margin: 5,
        fontSize: 15,
        background: "#ececec",
        padding: 7,
    },
    itemValue: {
        fontWeight: 'bold',
    }
}

const mapStateToProps = (state) => {
    return {
        hasRole: state.auth.hasRole
    }
}

export default connect(mapStateToProps)(AgenceList);
