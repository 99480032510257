import {API_BASE_URL} from 'constant'
import store from 'redux/store'

export function createZone(data){
  const url = `${API_BASE_URL}/zones`;
  const token = store.getState().auth.token

  return (
    fetch(url, {
      method:"POST",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body:JSON.stringify(data)
    })
  )
}

export function getZone(id){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/zones/${id}/find`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getZones(){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/zones`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getUserZones(){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/zones/filter`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getTeamZones(teamId){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/teams/${teamId}/zones`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function updateZone(data){
  const url = `${API_BASE_URL}/zones`;
  const token = store.getState().auth.token

  return (
    fetch(url, {
      method:"PUT",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body:JSON.stringify(data)
    })
  )
}

export function deleteZone(data){
  const url = `${API_BASE_URL}/zones/${data.id}`;
  const token = store.getState().auth.token
  
  return (
    fetch(url, {
      method:"DELETE",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body:JSON.stringify(data)
    })
  )
}
