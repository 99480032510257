import React, { useState, useEffect } from 'react';
import useMediaQuery from 'use-media-antd-query';
import { Drawer, Form, Button, Col, Row, Input, Modal, notification, DatePicker, Divider, Select, Spin } from 'antd';
import { ACCESS_TOKEN, API_BASE_URL } from 'constant';
import axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';
import InputNumberFormatFr from '../InputNumberFormatFr/InputNumberFormatFr';
// import { Option } from 'antd/lib/mentions';

const { Option } = Select;
const dateFormat = "DD-MM-YYYY"

function AddForm(props) {
  const [state, setState] = useState({
    loading: false,
    listAllSinistre: [],
    allowClear: false,
    isLoading: false,
    isLoadingCompagnie: false,
    previewVisible: false,
    previewTitle: "",
    list: [],
    codeCompagnie: null,
    file: ''

  })

  const [form] = Form.useForm();

  useEffect(() => {
    setState(state => ({ ...state, isLoading: true }));
    getAllSinistre();
    getAllCompagnie();
    getAllSinistreForCompagnie()
  }, [])

  function getAllSinistreForCompagnie() {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/sinistre/liste',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(res => {
        // console.log("TEST GET ALL SINISTRE ====================>>>>>>>>>>>>>>>>>>>", res)
        setState(state => ({
          ...state,
          isLoading: false,
          listAllSinistre: res.data
        }));
      });
  }

  function getAllSinistre() {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/sinistre/reclamation-effectue',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(res => {
        setState(state => ({
          ...state,
          isLoading: false,
          listAllSinistre: res.data
        }));
      });
  }

  function getAllCompagnie() {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + "/compagnie",
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(res => {
        setState(state => ({
          ...state,
          isLoadingCompagnie: true,
          list: res.data.data
        }));
        // console.log("compagnie end=================>", res)
      });
  }



  const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
      message: title,
      description: msg,
    });
  };

  function handleCloseModal() {
    setState(state => ({ ...state, previewVisible: false }));
  }

  function handleClose(user) {
    props.close(user);
  }

  const colSize = useMediaQuery();

  const onFinish = values => {
    // alert(JSON.stringify(values))
    setState(state => ({ ...state, isLoading: true }))
    const token = localStorage.getItem(ACCESS_TOKEN);
    let sinistre = {
      id: values.id,
      numeroPolice: values.numeroPolice,
      nomAssure: values.nomAssure,
      numeroImmatriculationAssure: values.numeroImmatriculationAssure,
      numeroSinistre: values.numeroSinistre,
      dateSinistre: values.dateSinistre,
      codeAdverse: state.codeCompagnie,
      compagnieAdverse: values.compagnieAdverse,
      codeCompagnie: state.codeCompagnie,
      numeroPoliceAdverse: values.numeroPoliceAdverse,
      nomAssureAdverse: values.nomAssureAdverse,
      numeroImmatriculationAssureAdverse: values.numeroImmatriculationAssureAdverse,
      // typeRecours: values.typeRecours,
      motifValidation: values.motifValidation,
      montantRecours: values.montantRecours,
      numeroSinistreAdverse: values.numeroSinistreAdverse,
      dateValidation: values.dateValidation,
    };
    // alert(JSON.stringify(sinistre))
    // const json = JSON.stringify(sinistre);
    // const blob = new Blob([json], {
    //   type: 'application/json'
    // });

    // let formData = new FormData();
    // formData.append('fileName', blob)
    // formData.append('file', state.file);


    console.log("file1", state.file)
    console.log("file2", state.fileName)

    const json = JSON.stringify(sinistre);
    const blob = new Blob([json], {
      type: "application/json"
    })
    let formData = new FormData();
    console.log(111111, state.file)
    formData.append('file', state.file);
    formData.append('fileName', state.fileName);
    formData.append('sinistre', blob);

    var config = {
      method: 'POST',
      url: API_BASE_URL + '/sinistre',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
      data: formData,
    };
    console.log("Avant save sinistre mono =================================>>>>>>>>>>>>>>>>>>>>>>>>>>", config)

    axios(config)
      .then(response => {
        console.log("save sinistre mono =================================>>>>>>>>>>>>>>>>>>>>>>>>>>", response)
        // console.log(response)
        setState(state => ({
          ...state,
          isLoading: false
        }))
        openNotificationWithIcon('success', 'Succès', 'Engeristrement effectué avec succès !!!');
        handleClose();
        getAllSinistre()
      })
      .catch(function (error) {
        openNotificationWithIcon('error', 'Erreur', 'Erreur cote client !!!');
        console.log(error);
        handleClose();
      });
  }

  let { list, listAllSinistre, isLoading } = state;

  function onChangeCodeCompagnie(value) {
    //console.log("1234 =========>>>>>>>>", value)
    setState(state => ({ ...state, codeCompagnie: value }))
  }

  const getUpdateFile = (e) => {
    let file = e.target.files[0];
    setState(state => ({ ...state, file: file, fileName: file.name }));
  }

  return (
    <>
      <Drawer
        title="Ajouter un sinistre"
        width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 900}
        onClose={() => handleClose()}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >

        {state.previewVisible && (
          <Modal
            visible={state.previewVisible}
            title={state.previewTitle}
            footer={null}
            onCancel={handleCloseModal}
          >
          </Modal>)
        }
        <Form layout="vertical" hideRequiredMark form={form} name="control-hooks" onFinish={onFinish}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div style={styles.itemContainerInfos}>
                <h5>Informations de la compagnie declarante</h5>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroPolice"
                id="numeroPolice"
                label="Numéro de police"
              // rules={[{ required: true, message: "Veuillez saisir le numero de police" }]}
              >
                <Input placeholder="Veuillez saisir le numero de police" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="montantRecours"
                id="montantRecours"
                label="Montant"
                rules={[{ required: true, message: "Veuillez saisir le montant recours" }]}
              >
                <InputNumberFormatFr placeholder="Veuillez saisir le montant recours" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="nomAssure"
                id="nomAssure"
                label="Nom assuré"
                rules={[{ required: true, message: "Veuillez saisir le nom assure" }]}
              >
                <Input placeholder="Veuillez saisir le nom assure" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroImmatriculationAssure"
                id="numeroImmatriculationAssure"
                label="Immatriculation"
              // rules={[{ required: true, message: "Veuillez saisir l'immatriculation" }]}
              >
                <Input placeholder="Veuillez saisir l'immatriculation" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="dateSinistre"
                id="dateSinistre"
                label="Date sinistre"
                rules={[{ required: true, message: 'Veuillez selection la date' }]}
                htmlFor="dateSinistre"
              >
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder="Veuillez selection la date"
                  allowClear={false}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroSinistre"
                id="numeroSinistre"
                label="Numero sinistre"
                rules={[{ required: true, message: "Veuillez saisir le numero de sinistre" }]}
              >
                <Input placeholder="Veuillez saisir le numero sinistre" />
              </Form.Item>
            </Col>

          </Row>
          <Divider />
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div style={styles.itemContainerInfos}>
                <h5>Informations de la compagnie adverse</h5>
              </div>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="compagnieAdverse"
                id="compagnieAdverse"
                label="Compagnie"
                rules={[{ required: true, message: "Veuillez selectionner la compagnie" }]}
              >
                <Select placeholder="Veuillez selectionner la compagnie" >
                  {state.list.map((m, index) => (
                    <Option value={m.id}>{m.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                name="nomCompagnie"
                id="nomCompagnie"
                label="Compagnie"
                rules={[{ required: true, message: 'Veuillez selectionner la compagnie' }]}
              >
                {state.isLoadingCompagnie ? (
                  <Select onChange={onChangeCodeCompagnie} placeholder="Veuillez selectionner la compagnie" >
                    {state.list.map((m, index) => (
                      <Option value={m.codeCompagnie}>{m.nomCompagnie}</Option>
                    ))}
                  </Select>
                ) : <Spin />}

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="nomAssureAdverse"
                id="nomAssureAdverse"
                label="Nom assuré"
              // rules={[{ required: true, message: "Veuillez saisir le nom de l'assure adverse" }]}
              >
                <Input placeholder="Veuillez saisir le nom de l'assure adverse" />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="codeAdverse"
                id="codeAdverse"
                label="Code"
                rules={[{ required: true, message: "Veuillez saisir le code compagnie adverse" }]}
              >
                <Input placeholder="Veuillez saisir le code compagnie adverse" />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                name="numeroPoliceAdverse"
                id="numeroPoliceAdverse"
                label="Numéro de police"
              // rules={[{ required: true, message: "Veuillez saisir le numero de police adverse" }]}
              >
                <Input placeholder="Veuillez saisir le numero de police adverse" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroImmatriculationAssureAdverse"
                id="numeroImmatriculationAssureAdverse"
                label="Immatriculation"
              // rules={[{ required: true, message: "Veuillez saisir l'immatriculation adverse" }]}
              >
                <Input placeholder="Veuillez saisir l'immatriculation adverse" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroSinistreAdverse"
                id="numeroSinistreAdverse"
                label="Numero sinistre"
                rules={[{ required: true, message: "Veuillez saisir le numero sinistre adverse" }]}
              >
                <Input placeholder="Veuillez saisir le numero sinistre adverse" />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="typeRecours"
                id="typeRecours"
                label="Type recours"
                rules={[{ required: true, message: "Veuillez saisir le type de recours" }]}
              >
                <Input placeholder="Veuillez saisir le type de recours" />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                name="dateValidation"
                id="dateValidation"
                label="Date validation"
              // rules={[{ required: true, message: "Veuillez saisir la date" }]}
              >
                {/* <Input placeholder="Veuillez saisir la date" /> */}
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder="Veuillez selection la date de validation"
                  allowClear={false}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>

            {/*  <Col span={12}>
              <Form.Item
                name="montantRecours"
                id="montantRecours"
                label="Montant"
                rules={[{ required: true, message: "Veuillez saisir le montant recours" }]}
              >
                <Input placeholder="Veuillez saisir le montant recours" />
              </Form.Item>
            </Col>

           <Col span={12}>
              <Form.Item
                name="annee1"
                id="annee1"
                label="Annee 1"
                rules={[{ required: true, message: "Veuillez saisir l'annee 1" }]}
              >
                <Input placeholder="Veuillez saisir l'annee" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="annee2"
                id="annee2"
                label="Annee 2"
                rules={[{ required: true, message: "Veuillez saisir l'annee 1" }]}
              >
                <Input placeholder="Veuillez saisir le montant recours" />
              </Form.Item>
            </Col> */}
          </Row><Divider />
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div style={styles.itemContainerInfos}>
                <h5>Autres informations</h5>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                name="motifValidation"
                id="motifValidationId"
                label="Motif de création"
                // rules={[{ required: true, message: 'Motif de création' }]}
                htmlFor=""
              >
                <TextArea rows="4" placeholder="Saisissez le motif de création du sinistre mono" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="file"
                id="file"
                label="Ajouter une preuve"
                rules={[{ required: true, message: 'Veuillez charger une preuve !!!' }]}
              // htmlFor=""
              >
                <input type="file" onChange={getUpdateFile} />
              </Form.Item>
            </Col>
          </Row>
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => handleClose()} style={{ marginRight: 8 }}>
              Annuler
            </Button>
            <Button
              disabled={state.loading}
              loading={state.loading}
              htmlType="submit" type="primary"
            >
              Enregistrer
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );

}
const styles = {
  itemContainer: {
    margin: 7,
    fontSize: 15
  },
  itemValue: {
    fontWeight: 'bold',
  },
  itemContainerInfos: {
    margin: 5,
    fontSize: 15,
    background: "#ececec",
    padding: 7,
  },
}

export default AddForm
