import React, { useEffect, useState } from 'react';

import { connect } from "react-redux";

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid'

import Routes from 'Routes';
import { Topbar, Footer, Dashbord } from 'components/layout'
import { logo } from 'assets/img/public';
import axios from 'axios';
import { Widget } from 'components/common';
import { handleService } from 'helpers';
import { getDashboardData } from 'services/dashboard';
import { CheckOutlined, ExclamationCircleOutlined, WarningOutlined, StopOutlined } from '@ant-design/icons';
import { ACCESS_TOKEN, AP, API_BASE_URL } from 'constant';
import NumberFormat from 'react-number-format';
import { Spin } from 'antd';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    background: 'rgb(241, 241, 241)'
  },
  content: {
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2)
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: 'center'
  },
  logo: {
    width: 169,
    [theme.breakpoints.up('sm')]: {
      width: "100%"
    }
  },
  breadcrumb: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: 30
    }
  },
  button: {
    height: 25,
    background: "rgb(241, 76, 76)"
  },
  item: {
    height: '100%'
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '2rem',
    height: '2rem',
    width: '2rem'
  },
}));



function App(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    nbSinistre: 0,
    nbSinistreAccept: 0,
    nbSinistreRejet: 0,
    nbSinistreAttente: 0,
    montantAttente: 0,
    montantConteste: 0,
    montantRegle: 0,
    montantNonRegle: 0,
    montantSinistreTotal: 0,

    isLoading: false
  })

  const [commande, setCommande] = useState({
    commande: [],
  })

  const { isAuthenticated } = props

  useEffect(() => {
    getAllSinistreCount()
  }, [])


  const getAllSinistreCount = values => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/sinistre/countTotal',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(response => {
        console.log('entete response --->', response)
        setState(state => ({
          ...state,
          nbSinistre: response.data.data?.countSinistreTotal,
          nbSinistreAttente: response.data.data?.countSinistreAttente,
          nbSinistreRejet: response.data.data?.countSinistreConteste,
          nbSinistreAccept: response.data.data?.countSinistreRegle,
          montantAttente: response.data.data?.montantSinistreAttente,
          montantConteste: response.data.data?.montantSinistreConteste,
          montantRegle: response.data.data?.montantSinistreRegle,
          montantTotal: response.data.data?.montantSinistreTotal,
          articleLoad: true,
        }))
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const children2 = (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        md={3}
        sm={6}
        xs={12}

      >
        <Widget
          className={classes.item}
          label={<><h5 style={{ color: 'white', fontSize: '14px' }}>Sinistres</h5></>}
          total={
            <div style={{ color: "#fff" }}>
              <h5 style={{ color: 'white', fontSize: '14px' }}>{'Nbre: '}
                <NumberFormat
                  value={state.nbSinistre}
                  displayType={"text"}
                  thousandSeparator={" "}
                />
                <br /></h5>
              <NumberFormat
                value={state.montantTotal}
                displayType={"text"}
                thousandSeparator={" "}
              />{" "}
              F CFA {" "}
            </div>
          }
          icon={<WarningOutlined style={{ color: "rgba(255, 120, 23,0.8)", fontSize: "35px" }} />}
          style={{ backgroundColor: '#bbbdbf' }}
        />
      </Grid>

      <Grid
        item
        md={3}
        sm={6}
        xs={12}
      >
        <Widget
          className={classes.item}
          label={<><h5 style={{ color: 'white', fontSize: '14px' }}>Sinistes reglés</h5></>}
          total={
            <div style={{ color: "#fff" }}>
              <h5 style={{ color: 'white', fontSize: '14px' }}>{'Nbre: '}
                <NumberFormat
                  value={state.nbSinistreAccept}
                  displayType={"text"}
                  thousandSeparator={" "}
                />
                <br /></h5>
              <NumberFormat
                value={state.montantRegle}
                displayType={"text"}
                thousandSeparator={" "}
              />{" "}
              F CFA {" "}
            </div>
          }
          // icon={<i className="fa fa-university" ></i>}
          icon={<CheckOutlined style={{ color: "rgba(255, 120, 23,0.8)", fontSize: "35px" }} />}  //<i className="fa fa-map-marker" style={{color: "rgba(255, 195, 15,0.7)", fontSize:"35px"}}></i>
          style={{ backgroundColor: '#929497' }}
        />
      </Grid>

      <Grid
        item
        md={3}
        sm={6}
        xs={12}
      >
        <Widget
          className={classes.item}
          label={<><h5 style={{ color: 'white', fontSize: '14px' }}>Sinistres contestés</h5></>}
          total={ /*valuedash3*/
            <div style={{ color: "#fff" }}>
              <h5 style={{ color: 'white', fontSize: '14px' }}>{'Nbre: '}
                <NumberFormat
                  value={state.nbSinistreRejet}
                  displayType={"text"}
                  thousandSeparator={" "}
                />
                <br /></h5>
              <NumberFormat
                value={'Nbre: ' + state.montantConteste}
                displayType={"text"}
                thousandSeparator={" "}
              />{" "}
              F CFA {" "}
            </div>
          }
          icon={<StopOutlined style={{ color: "rgba(255, 120, 23,0.8)", fontSize: "35px" }} />}
          style={{ backgroundColor: '#6d6e70' }}
        />
      </Grid>

      <Grid
        item
        md={3}
        sm={6}
        xs={12}
      >
        <Widget
          className={classes.item}
          label={<><h5 style={{ color: 'white', fontSize: '14px' }}>Sinistres non reglés</h5></>}
          total={
            <div style={{ color: "#fff" }}>
              <h5 style={{ color: 'white', fontSize: '14px' }}>{'Nbre: '}
                <NumberFormat
                  value={state.nbSinistreAttente}
                  displayType={"text"}
                  thousandSeparator={" "}
                />
                <br /></h5>
              <NumberFormat
                value={'Nbre: ' + state.montantAttente}
                displayType={"text"}
                thousandSeparator={" "}
              />{" "}
              F CFA {" "}
            </div>
          }
          icon={<ExclamationCircleOutlined style={{ color: "rgba(255, 120, 23,0.8)", fontSize: "35px" }} />}
          style={{ backgroundColor: '#414d56' }}
        />
      </Grid>
    </Grid>
  )

  if (!isAuthenticated) return <Routes />

  return (
    <div className={classes.root}>
      {/* {state.isLoading ? ( */}
      <>
        <Topbar />
        <div maxWidth="lg" className={classes.content}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={2} className={classes.logoContainer}>
                  <img
                    alt=''
                    className={classes.logo}
                    height={87}
                    src={logo}
                  />
                </Grid>

                <Grid item xs={12} md={10}>
                  {children2}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={2}>
                  <Dashbord />
                </Grid>

                <Grid item xs={12} md={10}>
                  <Routes />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <Footer />
      </>
      {/* // ):(<Spin/>)} */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps)(App);
