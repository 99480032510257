import React, { useState, useEffect } from 'react';

import useMediaQuery from 'use-media-antd-query';
import { Drawer, Form, Select, Button, Col, Row, Input, Modal, Upload, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import { ACCESS_TOKEN, API_BASE_URL, GET_USERS_FILES_CTR, STATUS_CODE_REST_API } from 'constant';
import { exeRequestFinal, exeRequest } from 'util/APIUtils';
import axios from 'axios';
import { getUserAsaci } from 'services/user';

const { Option } = Select;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function UpdateForm(props) {
  const { currentItem } = props

  const [state, setState] = useState({
    loading: false,
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [{
      url: GET_USERS_FILES_CTR + currentItem.username + ".png",
      uid: props.currentItem.id,
      name: 'image.png',
      status: 'done'
    }],
    roles: [],
    isUpdateDrawerVisible: false,
    roleLoad: false,
    currentUser: {},
    listUsers: []
  })

  const [form] = Form.useForm();

  useEffect(() => {
    setState(state => ({ ...state, isLoading: true }));
    getAllCodeCompagnie()
  },
    [])

  function handleClose(user) {
    props.close(user);
  }

  function handleCloseUpdate() {
    setState(state => ({ ...state, isUpdateDrawerVisible: false }))
  }

  // Notification
  const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
      message: title,
      description: msg,
    });
  };


  // Get all user
  const getAllCodeCompagnie = values => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/users',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(response => {
        console.log("GET ALL COMPAGNIE =====================>>>>>>>>>>>>>>", response)
        setState(state => ({
          ...state,
          listUsers: response.data.data,
          roleLoad: true,
        }))
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const onFinish = (values) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'post',
      url: API_BASE_URL + '/users/' + currentItem.id,
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
      data: values,
    };
    // alert(JSON.stringify(values))
    axios(config)
      .then(response => {
        console.log("reponse create user: ", response.data)
        setState(state => ({ ...state, response: state.listUsers }))
        openNotificationWithIcon('success', 'Succès', 'Modification effectuée avec succès !!!');
        handleClose()
        getAllCodeCompagnie()
      })
      .catch(function (error) {
        openNotificationWithIcon('error', 'Erreur', 'Une erreur est survenue lors de la modification !!!');
        handleClose()
        getAllCodeCompagnie()
      });
  }

  const colSize = useMediaQuery();

  return (
    <>
      <Drawer
        title={"Modification"}
        width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 720}
        onClose={() => handleCloseUpdate()}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >

        <Form layout="vertical" hideRequiredMark form={form} name="control-hooks" onFinish={onFinish}>
          <Form.Item name="id" initialValue={currentItem.id}>
            <Input type="hidden" />
          </Form.Item>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="codeCompagnie"
                label="Code compagnie"
                rules={[{ required: true, message: 'Veuillez saisir le code compagnie' }]}
                initialValue={currentItem.codeCompagnie}
              >
                <Input placeholder="Veuillez saisir le code compagnie" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="nomCompagnie"
                label="Nom compagnie"
                rules={[{ required: true, message: 'Veuillez saisir le nom de la compagnie' }]}
                initialValue={currentItem.nomCompagnie}
              >
                <Input placeholder="Veuillez saisir le nom de la compagnie" />
              </Form.Item>
            </Col>
          </Row>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => handleClose()} style={{ marginRight: 8 }}>
              Annuler
            </Button>
            <Button
              loading={state.loading}
              htmlType="submit" type="primary"
            >
              Enregistrer
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}

export default UpdateForm