import {API_BASE_URL} from 'constant'
import store from 'redux/store'

export function createAgence(data){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/agences`;

  return (
    fetch(url, {
      method:"POST",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body:JSON.stringify(data)
    })
  )
}

export function getAgences(){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/agences`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getUserAgences(){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/agences/filter`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getAgence(id){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/agences/${id}/find`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function updateAgence(data){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/agences`;

  return (
    fetch(url, {
      method:"PUT",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body:JSON.stringify(data)
    })
  )
}

export function deleteAgence(data){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/agences/${data.id}`;

  return (
    fetch(url, {
      method:"DELETE",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body:JSON.stringify(data)
    })
  )
}
