import { 
  SIGN_IN, 
  LOG_OUT, 
  ADD_TO_CART
 } from "./actionTypes";

export function signIn (token, user){
  return dispatch => {
    dispatch({
      type: SIGN_IN,
      token, 
      user
    })
  }
} 

export function logout (){
  return dispatch => {
    dispatch({
      type: LOG_OUT
    })
  }
} 


export function addToCart (value){
  return dispatch => {
    dispatch({
      type: ADD_TO_CART,
      value, 
    })
  }
} 



