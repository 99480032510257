import React, { Component } from 'react';

class PanierNbPrd extends Component {
    constructor(props){
        super(props);
        this.state = {
            panierVide:true,
            count:0,
        }
    }

    componentDidMount () {
        var content= JSON.parse(sessionStorage.getItem('panier'));
        //alert(content)
        if(content!== null && content!== '{}' && content!=='[]'){
            var nbprd=0;
            for(var i = 0; i< content.length; i++){
                nbprd+=Number(content[i]['quantiteCmd'])
            }
            this.setState({
                panierVide:false,
                count:nbprd
            })
        }else{
            this.setState({
                panierVide:true,
                count:0
            })
        }

        this.interval = setInterval(() => {
            var content= JSON.parse(sessionStorage.getItem('panier'));
            if(content!== null && content!== '{}' && content!=='[]'){
                var nbprd=0;
                for(var i =0; i<content.length; i++){
                    //////console.log(content[i]);
                    nbprd+=Number(content[i]['quantiteCmd'])
                }
                this.setState({
                    panierVide:false,
                    count:nbprd
                })
            }else{
                this.setState({
                    panierVide:true,
                    count:0
                })
            };
            //this.setState(({ count }) => ({ count: count + 1 }));
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        return (this.state.count).toLocaleString();
    }
}

export default PanierNbPrd;
