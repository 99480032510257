import React, { useEffect } from 'react';

import {connect} from "react-redux";

import AppBar from '@material-ui/core/AppBar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import makeStyles from '@material-ui/styles/makeStyles';

import ExpandMore from '@material-ui/icons/ExpandMore';

import { SecondTopbar } from './components'
import { avatar } from 'assets/img/public'

import { logout } from 'redux/actions'
import { Link, useHistory } from 'react-router-dom';
import { CURRENT_USER } from 'constant';
import { UserAddOutlined, PoweroffOutlined } from '@ant-design/icons';
import { ShoppingCartRounded } from '@material-ui/icons';
import PanierNbPrd from './PanierNbPrd';
import { logo } from 'assets/img/public'

const useStyles = makeStyles(theme => ({
	root: {
	    flexGrow: 1,
	},
	grow: {
	    flexGrow: 1,
	},
	company: {
		cursor: "pointer",
		color: "white",
		fontWeight:"bold",
		fontSize: 20
	},
	avatar: {
		width: 25,
		height: 25,
	},
	logo: {
		width: 70,
		height: 25,
	},
	profileContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginRight: 10,
	},
	profile: {
		marginRight: 10,
		cursor: "pointer",
		color: "white",
		fontWeight:"bold",
		fontSize: 16
	},
}));

function Topbar(props) {
	const { cart } = props

	const classes = useStyles();
	const history = useHistory()

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	}

	
	const handleClose = () => {
		setAnchorEl(null);
	}

	return (
	    <ElevationScroll {...props}>
	      	<AppBar color="default" style={{zIndex: 999}}>
		        <Toolbar variant="dense" style={{background: "#404040"}}>
			        <div className={classes.company} onClick={() => history.push('/')}>
						ASACI
					</div>

		          	<div className={classes.grow} />

					<div className={classes.profileContainer} >
						{/* <Link to="/panierDetail">
							<ShoppingCartRounded style={{color:'white', fontSize:"20px", marginRight:"10px", marginTop:'5px'}}/>
						</Link>
						<span 
							className="small badge" 
							style={{color:"white", marginLeft:'-15px', marginRight:"10px"}}
						>
							<Link to="/panierDetail" style={{color:'white'}}>
								<PanierNbPrd />
							</Link>
						</span> */}

						<Typography 
							color="primary"
							variant="body1"
							className={classes.profile}
							onClick={handleClick}
						>
							{localStorage.getItem(CURRENT_USER)}
							<ExpandMore />
						</Typography>

						<Menu
							id="profile"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							{/* <MenuItem onClick={handleClose}>
								<Link to={"/utilisateurs"} style={{color:"black"}}>
									<UserAddOutlined /> Ajouter un utilisateur
								</Link>
							</MenuItem>  */}
							<MenuItem onClick={props.logout}><PoweroffOutlined />  Se deconnecter</MenuItem>
						</Menu>
						<img
							alt=''
							className={classes.avatar}
							src={avatar}
						/>
						
					</div>
					
		        </Toolbar>
		        <SecondTopbar/>
	      	</AppBar>
	    </ElevationScroll>
	);
}

const mapStateToProps = (state) => {
    return {
	  user: state.auth.user,
	  cart: state.cart,
    }
}

export default connect(mapStateToProps, {logout})(Topbar);
  
function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
  });
}
