
import React, { useState, useRef } from 'react';

import { Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';


function useHandleSerach(){
    const [state, setState] = useState({
        searchText: '',
        searchedColumn: '',
    })

    const searchInput = useRef()

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Recherche...`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 200, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 110 }}
              >
                Recherche
              </Button>
              <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 100 }}>
                Initialiser
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => searchInput.current.select(), 100);
          }
        },
        render: text =>
          state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setState(state => ({
            ...state,
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        }));
    }
    
    const handleReset = clearFilters => {
        clearFilters();
        setState(state => ({...state, searchText: '' }));
    }

    return getColumnSearchProps
}

export default useHandleSerach