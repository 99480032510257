import { bg_geocrm } from 'assets/img/public'

export default (theme)=> ({
  root: {
    fontFamily: "Montserrat-Regular",
    // backgroundImage:`url(${bg_geocrm})`,
    backgroundColor:"#ececec",
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  formCard: {
    width: 400,
    [theme.breakpoints.only('xs')]: {
      width: 300
    },
    borderRadius: 5
  },
  title: {
    fontSize: 28,
    textAlign:"center",
    [theme.breakpoints.only('xs')]: {
      fontSize: 15,
    },
    fontWeight: 700,
    color: '#3a0909',
    marginBottom: 20,
    '& i': {
      color: '#3a0909',
      fontSize: 37,
      [theme.breakpoints.only('xs')]: {
        fontSize: 30,
      },
    }
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
    marginBottom: 20,
    borderBottom: '1px solid gray'
  },
  logo: {
    width: 300,
    height: 100,
    [theme.breakpoints.only('xs')]: {
      width: 200,
      height: 100,
    },
  },
  logo2: {
    width: 30,
    height: 10,
    [theme.breakpoints.only('xs', 'md', 'sm')]: {
      width: 20,
      height: 10,
    },
  },
  loginBtn: {
    textAlign: 'center',
  }
});
