import {API_BASE_URL} from 'constant'
import store from 'redux/store'

export function updateClient({id, data}){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/clients/${id}`;

  return (
    fetch(url,{
      method:"POST",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body:JSON.stringify(data)
    })
  )
}

export function getClient(id){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/clients/${id}`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getZoneClients(zoneId){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/zones/${zoneId}/clients`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getTeamClients(teamId){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/clients/teams/${teamId}`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getAgenceClients(agenceId){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/clients/agences/${agenceId}`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}