import React, { Fragment, useState, useEffect, useRef } from 'react';

import { connect } from "react-redux";
import { Card, Table, Input, Button, Divider, Form, Row, Col, message, Select, notification, Drawer } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ACCESS_TOKEN, API_BASE_URL } from 'constant';
import axios from 'axios';
import TextArea from 'rc-textarea';
import * as Helpers from 'util/Helpers';
import UpdateForm from './UpdateForm'
import { handleService } from 'helpers'
import { getUser } from 'services/user';

const key = 'updatable';

function UserList(props) {
    const [state, setState] = useState({
        users: [],
        isLoading: false,
        open: false,
        listRole: [],
        roleLoad: false,
        data: {},
        visible: false,
        currentData: {},
        loading: false,
        listUsers: [],
        isUpdateDrawerVisible: false,
        listCompagnie: []

    })

    useEffect(() => {
        getAllRole();
        getAllUsers();
        getAllCodeCompagnie();
    }, [])


    // Notification
    const openNotificationWithIcon = (type, title, msg) => {
        notification[type]({
            message: title,
            description: msg,
        });
    };

    const onFinish = (value) => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'post',
            url: API_BASE_URL + '/auth/signup',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
            data: value,
        };
        axios(config)
            .then(response => {
                console.log("reponse create user: ", response.data)
                setState(state => ({ ...state, response: state.listUsers }))
                openNotificationWithIcon('success', 'Succès', 'Engeristrement effectué avec succès !!!');
                getAllRole()
                hideAddModal()
                onClose()
                getAllUsers()
                getAllCodeCompagnie()
                window.location.reload()
            })
            .catch(function (error) {
                console.log("error save user ==============>>>>>", error.response)
                openNotificationWithIcon('error', 'Erreur', error.response.data?.message);
                console.log(error);
            });
    }


    const getAllRole = values => {

        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/role',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                setState(state => ({
                    ...state,
                    listRole: response.data,
                    roleLoad: true,
                }))
                console.log("reponse get role: ", response.data)
                console.log("get role au state: ", state.listRole)
                //window.location.href="/stock";
                //alert("new article: "+ JSON.stringify(newArticle.data))
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getAllCodeCompagnie = values => {

        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/compagnie',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                setState(state => ({
                    ...state,
                    listCompagnie: response.data.data,
                    roleLoad: true,
                }))
                console.log(1111111111111111111111122222222222, response)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getAllUsers = values => {

        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/users',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                setState(state => ({
                    ...state,
                    listUsers: response.data.data,
                    roleLoad: true,
                }))
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function handleCloseUpdate() {
        setState(state => ({ ...state, isUpdateDrawerVisible: false }))
    }



    const columns = [
        {
            title: 'Code compagnie',
            dataIndex: 'codeCompagnie',
            key: 'codeCompagnie',
        },
        {
            title: 'Nom compagnie',
            dataIndex: 'nomPrenom',
            key: 'nomPrenom',
        },
        {
            title: 'Nom utilisateur',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Telephone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'roles',
            key: 'roles',
            render: (roles) => (
                <div>{roles.map((elem) => elem.name).join(" , ")}</div>
            ),
        },
        {
            title: 'Raison sociale',
            dataIndex: 'raisonSocial',
            key: 'raisonSocial',
        },
        {
            key: 'actions',
            title: "Actions",
            render: (text, item) => (
                <Fragment>
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        size="smail"
                        onClick={() => setState(state => ({ ...state, currentItem: item, isUpdateDrawerVisible: true }))}
                    />

                </Fragment>
            )
        },
    ];

    const { Option } = Select;

    function hideAddModal() {
        setState(state => ({ ...state, open: false }))
    }

    const [form] = Form.useForm();

    const showDrawer = (compagnie) => {
        setState(state => ({
            ...state,
            visible: true,
            currentData: compagnie
        }));
    };

    const onClose = () => {
        setState(state => ({
            ...state,
            visible: false,
        }));
    };


    return (
        <Fragment>
            <Card>
                <div className="container-fluid">
                    <div style={{ display: 'inline-block', float: 'left' }}>
                        <Button type="primary" onClick={showDrawer}>
                            <PlusOutlined /> Ajouter un utilisateur
                        </Button>
                    </div><br />

                </div>


                <Divider />

                <div style={{ overflow: "auto", padding: 5 }}>
                    <Table
                        columns={columns}
                        dataSource={state.listUsers}
                    />
                </div>
            </Card>

            {state.isUpdateDrawerVisible && (
                <UpdateForm
                    visible={state.isUpdateDrawerVisible}
                    currentItem={state.currentItem}
                    close={handleCloseUpdate}

                />
            )}

            <Drawer
                width={900}
                title="Ajouter un utilisateur"
                //  width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 900}
                placement="right"
                closable={false}
                //onClose={onClose}
                visible={state.visible}
            >
                <Form layout="vertical" hideRequiredMark form={form} name="control-hooks" onFinish={onFinish}>
                    <Row gutter={[24, 24]}>
                        <Col span={12}>
                            <Form.Item
                                name="nomPrenom"
                                id="nomPrenom"
                                label="Nom compagnie"
                                rules={[{ required: true, message: "Veuillez saisir le nom de la compagnie" }]}
                            >
                                <Input placeholder="Veuillez saisir le nom de la compagnie" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="codeCompagnie"
                                id="codeCompagnie"
                                label="Code compagnie"
                                rules={[{ required: true, message: "Veuillez selectionner le code compagnie" }]}
                            >
                                {/* <Input placeholder="Veuillez saisir le code compagnie" /> */}
                                <Select placeholder="Veuillez selectionner le code compagnie">
                                    {state.listCompagnie.map((m, index) => (
                                        <Option value={m.codeCompagnie}>{m.codeCompagnie}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="username"
                                id="username"
                                label="Nom utilisateur"
                                rules={[{ required: true, message: "Veuillez saisir le nom utilisateur" }]}
                            >
                                <Input placeholder="Veuillez saisir le nom de le nom utilisateur" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="role"
                                id="role"
                                label="Role"
                                rules={[{ required: true, message: "Veuillez selectionner le role" }]}
                            >
                                <Select placeholder="Veuillez selectionner le role">
                                    {state.listRole.map((m, index) => (
                                        <Option value={m.name}>{m.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Mot de passe"
                                name="password"
                                rules={[{ required: true, message: 'Veuillez saisir le mot de passe' }]}
                                hasFeedback
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="confirm"
                                label="Confirmez le mot de passe"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez repeter le mot de passe',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject('Les deux mots de passe ne correspondent pas');
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="phone"
                                id="phone"
                                label="Telephone"
                                rules={[
                                    { required: true, message: "Veuillez saisir le numero d'utilisateur" },
                                ]}
                            >
                                {/* <Input placeholder="Veuillez saisir le numero d'utilisateur" /> */}
                                <Input
                                    style={{ width: '100%' }}
                                    maxLength={10}
                                    addonBefore="+225"
                                    placeholder="Veuillez saisir le téléphone"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="email"
                                id="email"
                                label="Email"
                                rules={[
                                    { required: true, message: "Veuillez saisir l'email" },
                                    { type: 'email', message: "Votre email valide" },
                                ]}
                            >
                                <Input placeholder="Veuillez saisir votre email" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="raisonSociale"
                                id="raisonSociale"
                                label="Raison sociale"
                                rules={[
                                    { required: true, message: "Veuillez saisir la raison social" },
                                ]}
                            >
                                <Input rows={4} placeholder="Veuillez saisir la raison social" />
                            </Form.Item>
                        </Col>
                    </Row><br /><br />

                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
                            Fermer
                        </Button>,
                        <Button
                            disabled={state.loading}
                            loading={state.loading}
                            htmlType="submit" type="primary"
                        >
                            Enregistrer
                        </Button>
                    </div>
                </Form>
            </Drawer>

            {/* ================================= Update =========================================== */}

            {/* <Drawer
                title={`${currentItem.username}`}
                width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 720}
                onClose={() => handleCloseUpdate()}
                visible={props.visible}
                bodyStyle={{ paddingBottom: 80}}
            >

                <Form layout="vertical" hideRequiredMark form={form} name="control-hooks" onFinish={onFinish}>
               

                <Row gutter={16}>
                    <Col span={24}>
                    <Form.Item
                        name="codeCompagnie"
                        label="Code compagnie"
                        rules={[{ required: true, message: 'Veuillez saisir le code' }]}
                        initialValue={currentItem.codeCompagnie}
                    >
                        <Input placeholder="Veuillez saisir le code compagnie" />
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                    <Form.Item
                        name="nomPrenom"
                        label="Nom compagnie"
                        rules={[{ required: true, message: 'Veuillez saisir le login' }]}
                        initialValue={currentItem.nomPrenom}
                    >
                        <Input placeholder="Veuillez saisir le login" />
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item
                        name="username"
                        label="Nom utilisateur"
                        rules={[{ required: true, message: 'Veuillez saisir le Nom utilisateur' }]}
                        initialValue={currentItem.username}
                    >
                        <Input placeholder="Veuillez saisir le login" />
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="phone"
                        label="Téléphone"
                        rules={[{ required: true, message: 'Veuillez saisir le téléphone' }]}
                        initialValue={currentItem.phone}
                    >
                        <Input
                        style={{ width: '100%' }}
                        addonBefore="+225"
                        placeholder="Veuillez saisir le téléphone"
                        />
                    </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                        { required: true, message: "Veuillez saisir l'email" },
                        {type: 'email', message: "Votre email valide"},
                        ]}
                        initialValue={currentItem.email}
                    >
                        <Input placeholder="Veuillez saisir l'email" />
                    </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                    <Form.Item
                        name="raisonSocial"
                        label="Raison sociale"
                        rules={[{ required: true, message: 'Veuillez saisir la raison sociale' }]}
                        initialValue={currentItem.raisonSocial}
                    >
                        <Input placeholder="Veuillez saisir la raison sociale" />
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item
                        name="roleId"
                        label="Role"
                        rules={[{ required: true, message: 'Veuillez selectionner le role' }]}
                        initialValue={currentItem.name}
                    >
                        <Select placeholder="Veuillez selectionner le role">
                        {state.roles.map(( m, index) => (
                            <Option key={m.id} value={m.id}>{m.name}</Option>
                        ))}
                        </Select>
                    </Form.Item>
                    </Col>
                </Row>

                <div
                    style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                    }}
                >
                    <Button onClick={() => handleClose()} style={{ marginRight: 8 }}>
                    Annuler
                    </Button>
                    <Button   
                    loading={state.loading} 
                    htmlType="submit" type="primary"
                    >
                    Enregistrer
                    </Button>
                </div>
                </Form>
            </Drawer> */}

        </Fragment>
    )
}

const styles = {
    itemContainer: {
        margin: 7,
        fontSize: 15
    },
    itemContainerInfos: {
        margin: 5,
        fontSize: 15,
        background: "#ececec",
        padding: 7,
    },
    itemValue: {
        fontWeight: 'bold',
    }
}


export default connect()(UserList);