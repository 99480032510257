import React, { Component } from 'react';
import { Steps, Button, message } from 'antd';
import UploadStep1 from 'pages/Importation/UploadStep1';


class AfficheUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            fileAttestation:{
                souscripteurId : "",
                assureName : "",
                assureurId : "",
                assureurName : "",
                numeroPolice : "",
                startDate : "",
                endDate : "",
                fileName : "",
                title:"",
                attestations:[]
            }
        };
    }

    next(props) {
        const current = this.state.current + 1;
        this.setState({ current });
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({current});
    }

    updateFileAttestation = info =>{
        this.setState({
            fileAttestation:info
        })
    }

    render(){
        const { current } = this.state;
        return (
            <div>

                <div className="container-fluid m-0 pl-4 text-left" style={{backgroundColor:"white"}}>
                    <div className="row m-0 p-0">
                            <div style={{width:"100%"}}>
                                <div className="card-body m-0 p-0">
                                    <hr/>
                                    <UploadStep1 />
                                    <br/>
                                    <br/>
                                </div>
                            </div>
                        </div>
                    <br/>
                    <br/>
                    <br/>
                </div>
            </div>
        );
    }
}
export default AfficheUpload;
