import React, { useEffect, useState } from 'react';
import { Card, Table, Typography, Divider, Input, Tag, Spin, Col, Row, Tabs } from 'antd';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { ACCESS_TOKEN, API_BASE_URL } from 'constant';
import axios from 'axios';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PieChart from "highcharts-react-official";
import apiService from 'services/api.service';
import useHandleSearch from 'hooks/useHandleSearch'




const { Title } = Typography
const { TabPane } = Tabs;

export default function ClientCarte(props) {
  useEffect(() => {
    getAllReglement();
    getAllSinistre();
    graphDashboard();

  }, [])


  const [state, setState] = useState({
    chartData: {},
    lightOptions: {},
    listStock: [],
    listEmplacementChartData: [],
    listArticleChartData: [],
    listMesReglementValideChartData: [],
    listPieChartData: [],
    stockLoad: false,
    listMesReglementValide: [],
    commadeLoad: false,
    searchInputCmd: null,
    searchInput: null,
    listStockChartData: [],
    series: [],
    options: {},
    listSinistre: [],
    reglementLoad: false,

    /**graph proprieté */
    dataAttente: [],
    dataRegle: [],
    dataConteste: [],
    pieDataAttente: 0,
    pieDataConteste: 0,
    pieDataRegle: 0,

  })


  /*graph*/
  const graphDashboard = () => {
    apiService.getAll(API_BASE_URL + "/graph/dashboard")
      .then((response) => {
        console.log("response dashborddd ", response)
        setState(state => ({
          ...state, dataAttente: response.data.data.lineGraphResponse.dataAttente,
          dataRegle: response.data.data.lineGraphResponse.dataRegle,
          dataConteste: response.data.data.lineGraphResponse.dataConteste,

          pieDataAttente: response.data.data.pieGraphResponse.dataAttente,
          pieDataConteste: response.data.data.pieGraphResponse.dataConteste,
          pieDataRegle: response.data.data.pieGraphResponse.dataRegle,

        }))
      }).catch((error) => {

      })
  }
  //fin


  const LineChartOptions = {
    title: {
      text: 'Graphe mensuel des montants de sinistres'
    },
    xAxis: {
      categories: ['Janv', 'Fév', 'Mars', 'Avril', 'Mai', 'Juin', 'Juil', 'Aout', 'Sept', 'oct', 'Nov', 'Déc']
    },
    colors: ['#17A8F5', '#32CD32', '#FB8833'],

    yAxis: {
      title: {
        text: 'Montant (FCFA)'
      }
    },
    series: [
      {
        name: 'Attente',
        data: state.dataAttente//[41, 79, 57, 47, 63, 71,0,5,0,0,20,15]
      },
      {
        name: 'Reglé',
        data: state.dataRegle//[21, 35, 75, 51, 41, 47,0,0,12,0,2,0]
      },
      {
        name: 'Contesté',
        data: state.dataConteste//[21, 35, 75, 51, 41, 47,0,0,12,0,2,0]
      }

    ],
    credits: {
      enabled: false
    }
  }

  const BarChartOptions = {
    chart: {
      //type: 'column'
      type: 'pie',
    },

    title: {
      text: 'Graphe annuel des sinistres'
    },

    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },

    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -10,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
      shadow: true
    },

    series: [{
      name: 'sinistre',
      colorByPoint: true,
      data: [
        {
          name: 'Attente',
          y: state.pieDataAttente,
          //sliced: true,
          selected: true,
          color: '#17A8F5'

        },
        {
          name: 'Reglé',
          y: state.pieDataRegle,
          //sliced: true,
          selected: true,
          color: '#32CD32'
        }
        ,
        {
          name: 'Contesté',
          y: state.pieDataConteste,
          //sliced: true,
          selected: true,
          color: '#FB8833'
        }
      ]

    }],
    credits: {
      enabled: false // desactive le text highchart
    },
    //showInLegend: true,
  }





  const getAllReglement = values => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/sinistre/validation-du-jour',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(response => {
        setState(state => ({
          ...state,
          listMesReglementValide: response.data.data,
          reglementLoad: true,
        }))
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getAllSinistre = values => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/sinistre/attente-du-jour',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(response => {
        setState(state => ({
          ...state,
          listSinistre: response.data.data,
          reglementLoad: true,
        }))
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  // Table article
  const columns = [
    {
      title: 'Date creation', width: 300, key: 'createdAt',
      dataIndex: "createdAt",
    },
    {
      title: 'Code compagnie', width: 240, key: 'codeAdverse',
      dataIndex: "codeAdverse",
      // ...getColumnSearchProps('codeAdverse'),
    },
    {
      title: 'Compagnie', width: 190, key: '10',
      dataIndex: "compagnieAdverse",
      // ...getColumnSearchProps('compagnieAdverse'),
    },
    {
      title: 'Numéro sinistre', width: 280, key: 'numeroSinistreAdverse',
      dataIndex: "numeroSinistreAdverse",
      // ...getColumnSearchProps('numeroSinistreAdverse'),
    },
    {
      title: 'Numéro Police',
      width: 220, key: 'numeroPoliceAdverse', dataIndex: "numeroPoliceAdverse",
      // ...getColumnSearchProps('numeroPoliceAdverse'),
    },
    {
      title: "Nom assuré", width: 220, key: 'nomAssureAdverse',
      dataIndex: "nomAssureAdverse",
      // ...getColumnSearchProps('nomAssureAdverse'),
    },
    {
      title: "Numéro d'Immatriculation", width: 350, key: 'numeroImmatriculationAssureAdverse',
      dataIndex: "numeroImmatriculationAssureAdverse",
      // ...getColumnSearchProps('numeroImmatriculationAssureAdverse'),
    },
    { title: "Montant du recours", width: 200, key: '17', dataIndex: "montantRecours" },
    // { title: 'Année 1', width: 120, key: '19' ,dataIndex:"annee1"},
    // { title: 'Année 2', width: 110, key: '20' ,dataIndex:"annee2"},
    { title: 'Selection', width: 120, key: '21', dataIndex: "selection" },
  ];



  const dataSource = state.listCommande;

  const commandeColumns = [
    { title: 'Date creation', width: 300, key: 'createdAt', dataIndex: "createdAt" },
    {
      title: 'Code compagnie', width: 240,
      key: 'codeAdverse', dataIndex: "codeAdverse",
      // ...getColumnSearchProps('codeAdverse'),
    },
    {
      title: 'Compagnie', width: 190,
      key: 'compagnieAdverse', dataIndex: "compagnieAdverse",
      // ...getColumnSearchProps('compagnieAdverse'),
    },
    {
      title: 'Numéro sinistre',
      width: 280, key: 'numeroSinistreAdverse',
      dataIndex: "numeroSinistreAdverse",
      // ...getColumnSearchProps('numeroSinistreAdverse'),
    },
    {
      title: 'Numéro Police', width: 220,
      key: 'numeroPoliceAdverse', dataIndex: "numeroPoliceAdverse",
      // ...getColumnSearchProps('numeroPoliceAdverse'),
    },
    {
      title: "Nom assuré", width: 220,
      key: 'nomAssureAdverse',
      dataIndex: "nomAssureAdverse",
      // ...getColumnSearchProps('nomAssureAdverse'),
    },
    {
      title: "Numéro d'Immatriculation", width: 350,
      key: 'numeroImmatriculationAssureAdverse', dataIndex: "numeroImmatriculationAssureAdverse",
      // ...getColumnSearchProps('numeroImmatriculationAssureAdverse'),
    },
    { title: "Montant du recours", width: 200, key: '17', dataIndex: "montantRecours" },
    // { title: 'Année 1', width: 120, key: '19' ,dataIndex:"annee1"},
    // { title: 'Année 2', width: 110, key: '20' ,dataIndex:"annee2"},
    { title: 'Selection', width: 120, key: '21', dataIndex: "selection" }

  ];


  const getColumnSearchProps = useHandleSearch()

  return (

    <>


      <div className="row">
        <div className="section col-md-6">
          {/* <h3 className="section-title">Sinistre</h3> */}
          <div className="section-content">
            <HighchartsReact
              highcharts={Highcharts}
              options={LineChartOptions}
            />
          </div>
        </div>

        <div className="section col-md-6">
          {/* <h3 className="section-title">Bar Chart</h3> */}
          <div className="section-content">
            <PieChart
              highcharts={Highcharts}
              options={BarChartOptions}
            />
          </div>
        </div>
      </div>



      <Divider />

      <Card>
        {(localStorage.getItem('role') === "ROLE_COMPAGNIE") ? (
          <>
            <Title level={3} style={{ color: "#3a0909" }}>Derniers sinistres adverse en attente</Title>
            <div>
              {/* <div style={{float:"right"}}>
                      <Input placeholder="Recherche" onChange={handlesearchInput}/>
                  </div> */}
              <br />
              <Divider />
            </div>
            {state.reglementLoad ? (
              <Table
                columns={columns}
                dataSource={state.listSinistre}
                scroll={{ x: 2000 }}
              />
            ) : (
              <Spin />
            )}
          </>

        ) : (
          <>
            <Title level={3} style={{ color: "#3a0909" }}>Derniers sinistres en attente</Title>
            <div>
              {/* <div style={{float:"right"}}>
                  <Input placeholder="Recherche" onChange={handlesearchInput}/>
              </div> */}
              <br />
              <Divider />
            </div>
            {state.reglementLoad ? (
              <Table
                columns={columns}
                dataSource={state.listSinistre}
                scroll={{ x: 2000 }}
              />
            ) : (
              <Spin />
            )}
          </>
        )}


      </Card>

      <Divider />

      <Card>

        <Title level={3} style={{ color: "#3a0909" }}>Derniers regléments</Title>
        <div>
          {/* <div style={{float:"right"}}>
                <Input placeholder="Recherche" onChange={handlesearchInputCmd}/>
            </div> */}
          <br />
          <Divider />
        </div>

        {state.reglementLoad ? (
          <Table
            columns={commandeColumns}
            dataSource={state.listMesReglementValide}
            scroll={{ x: 2000 }}
          />
        ) : (
          <Spin />
        )}
      </Card>
    </>
  );
}