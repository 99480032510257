import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {PrivateRoute} from 'components/common';
import Login from 'pages/Login';
import Users from 'pages/Users';
import Accueil from 'pages/Accueil';
import Commande from 'pages/Reglement';
import Reporting from 'pages/Reporting';
import Importation from 'pages/Importation';
import Sinistre from 'pages/Sinistre';
import Reglement from 'pages/Reglement';
import Contester from 'pages/Contester';
import ReclamationEffectuees from 'pages/ReclamationEffectuees';
import ReclamationRecues from 'pages/ReclamationRecues';
import Compagnie from 'pages/Compagnie';

export default function Routes() {


  return (
    // {state.loading ? (
      <>
      <Switch>
        <PrivateRoute 
          component={Accueil}
          exact
          path="/"
        />

        <PrivateRoute
          component={Users}
          exact
          path="/utilisateurs"
        />
        <PrivateRoute
          component={Compagnie}
          exact
          path="/compagnie"
        />

        <PrivateRoute
          component={Sinistre}
          exact
          path="/sinistre"
        />

        <PrivateRoute
          component={ReclamationEffectuees}
          exact
          path="/reclamationEffectuees"
        />

        <PrivateRoute
          component={ReclamationRecues}
          exact
          path="/reclamationRecue"
        />

        <PrivateRoute 
          component={Reglement}
          exact
          path="/reglement"
        />

        <PrivateRoute 
          component={Commande}
          exact
          path="/reglement"
        />

        <PrivateRoute 
          component={Importation}
          exact
          path="/importation"
        />

        <PrivateRoute 
          component={Reporting}
          exact
          path="/reporting"
        />

        <Route 
          component={Login}
          exact
          path="/login"
        />

        {/* <Route 
          component={NewUser}
          exact
          path="/newUser"
        /> */}

        <PrivateRoute 
          component={Contester}
          exact
          path="/contestation"
        />
      
        <Redirect to="/" />

      </Switch>
    </>
    // )}
  );
}
