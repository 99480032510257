import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, Card, Col, Divider, Drawer, Form, Input, message, notification, Row, Select, Spin, Table, Tabs } from 'antd';
import 'antd/dist/antd.css';
import Modal from 'antd/lib/modal/Modal';
import axios from 'axios';
import { ACCESS_TOKEN, API_BASE_URL, CURRENT_USER } from 'constant';
import AddForm from 'components/layout/Dashbord/AddForm';
import UploadStep1 from 'pages/Importation/UploadStep1';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import useMediaQuery from 'use-media-antd-query';
import useHandleSearch from 'hooks/useHandleSearch'
import { Backdrop, CircularProgress } from '@material-ui/core';

const api_upload = API_BASE_URL + '/files/upload';




const { Option } = Select;


const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

const useStyles = makeStyles(theme => ({
    loginBtn1: {
        textAlign: 'right',
        float: 'right',
        display: 'inline-block',
        marginTop: "-15px",
        popvisible: false,
        updateLigne: []
    },

    loginBtn2: {
        textAlign: 'left',
        float: 'right',
        display: 'inline-block',
        paddingRight: "9px",
        marginTop: "-15px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));



const pageSize = 10
const key = 'updatable';
const { TabPane } = Tabs;

function AgenceList(props) {
    const { hasRole } = props
    const searchInput = useRef()
    const [form] = Form.useForm();

    const [state, setState] = useState({
        isLoading: false,
        visible: false,
        listSinistreAdmin: [],
        listSinistreCompagnie: [],
        inputValue: "",
        searchInput: "",
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
        listSinistreAdverse: [],
        modal2Visible: false,
        datasource: [],
        file: {},
        operations: "",
        value: 'value',
        visible: false,
        currentData: {},
        sinistreLoad: false,
        isAddDrawerVisible: false,
        selectValue: "",
        listUser: [],
        openBackdrop: false
    })

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen(!open);
    };

    useEffect(() => {
        getAllSinistre();
        // getAllSinistreAdverse();
    }, [])

    const getColumnSearchProps = useHandleSearch()

    const listSinistre = state;

    const getAllSinistre = values => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/sinistre/attente',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                setState(state => ({
                    ...state,
                    sinistreLoad: true,
                    listSinistreCompagnie: response.data.data?.attenteCompagnie,
                    listSinistreAdverse: response.data.data.attenteAdverse?.map(item => ({ ...item, key: item.id })),
                    listSinistreAdmin: response.data.data?.attenteAdmin,

                }))
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // Colum admin
    const columnsAdmin = [
        {
            title: 'Date creation',
            width: 200,
            key: '1',
            dataIndex: "createdAt",
        },
        {
            title: 'Code compagnie beneficiaire',
            width: 200, key: 'code',
            dataIndex: "code",
            ...getColumnSearchProps('code'),
        },
        {
            title: 'Code compagnie adverse',
            width: 190, key: 'codeAdverse',
            dataIndex: "codeAdverse",
            ...getColumnSearchProps('codeAdverse'),
        },

        {
            title: 'Date sinistre',
            width: 260, key: 'dateSinistre',
            dataIndex: "dateSinistre",
            ...getColumnSearchProps('dateSinistre'),
        },
        {
            title: 'Action',
            key: '23',
            fixed: 'right',
            width: 100,
            render: (text, sinistre) => (
                <>
                    <EyeOutlined onClick={() => showDrawer(sinistre)} />
                </>
            ),
        },
    ];

    // Colonnes des sinistre de la compagnie declarante emis
    const columns = [
        {
            title: 'Date creation',
            width: 200,
            key: '1',
            dataIndex: "createdAt",
        },
        {
            title: 'Code compagnie adverse',
            width: 200, key: '11',
            dataIndex: "codeAdverse",
            ...getColumnSearchProps('codeAdverse'),
        },

        {
            title: 'Compagnie adverse',
            width: 190, key: '10',
            dataIndex: "compagnieAdverse",
            ...getColumnSearchProps('compagnieAdverse'),
        },
        {
            title: "Nom assuré",
            width: 200, key: '14',
            dataIndex: "nomAssureAdverse",
            ...getColumnSearchProps('nomAssureAdverse'),
        },
        {
            title: 'Action',
            key: '23',
            fixed: 'right',
            width: 100,
            render: (text, sinistre) => (
                <>
                    <EyeOutlined onClick={() => showDrawer(sinistre)} />
                </>
            ),
        },
    ];

    // Colone de la compagnie adverse
    const columns1 = [
        {
            title: 'Date creation',
            width: 200, key: '1',
            dataIndex: "createdAt"
        },
        {
            title: 'Code compagnie', width: 240, key: '11',
            dataIndex: "codeAdverse",
            ...getColumnSearchProps('codeAdverse'),
        },
        {
            title: 'Compagnie',
            width: 190, key: '10',
            dataIndex: "compagnieAdverse",
            ...getColumnSearchProps('compagnieAdverse'),
        },
        {
            title: "Nom assuré",
            width: 200, key: '14',
            dataIndex: "nomAssureAdverse",
            ...getColumnSearchProps('nomAssureAdverse'),
        },
        // { title: "Montant du recours", width:200, key: '17' ,dataIndex:"montantRecours"},
        {
            title: 'Action',
            key: '23',
            fixed: 'right',
            width: 100,
            render: (text, sinistre) => (
                <>
                    <EyeOutlined onClick={() => showDrawer(sinistre)} />
                </>
            ),
        },
    ];

    // Notification
    const openNotificationWithIcon = (type, title, msg) => {
        notification[type]({
            message: title,
            description: msg,
        });
    };


    // Checkbook
    // console.log("log file" , state.file) // Console pour la selection du fichier

    const start = (e) => {
        if (!state.operations || isNaN(state.operations)) {
            message.warning("Veuillez selectionner le type d'opération !!!");
            return false;
        }
        let infoValue = state.operations
        setState(state => ({ ...state, loading: true }));
        const token = localStorage.getItem(ACCESS_TOKEN);
        const validationRequest = {
            listId: selectedRowKeys,
            statut: infoValue
        }
        const json = JSON.stringify(validationRequest);
        const blob = new Blob([json], {
            type: "application/json"
        })
        let formData = new FormData();
        formData.append('file', (state.file != null ? state.file : ""));
        formData.append('validationRequest', blob);

        setState(state => ({
            ...state,
            selectedRowKeys: [],
            loading: false,
        }));

        const url = API_BASE_URL + '/sinistre/validation';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": 'Bearer ' + token
            },
            data: formData
        };
        axios(url, options)
            .then(response => {
                // console.log(response)
                setState(state => ({ ...state, isLoading: false }))
                // console.log("reponse add file: ", response.data)

                openNotificationWithIcon('success', 'Succès', 'Operartion effectuée avec succès !!!')
                setModal2Visible(false)
                setState(state => ({ ...state, }))
                getAllSinistre()
                window.location.href = "/sinistre"
            })
            .catch(function (error) {
                // openNotificationWithIcon('error','Erreur','Erreur cote client !!!');
                console.log(error);
            })
    };

    const onSelectChange = (selectedRowKeys) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        setState(state => ({ ...state, selectedRowKeys }));
    };

    const { loading, selectedRowKeys } = state;

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    // Function validation 

    function setModal2Visible(modal2Visible) {
        setState(state => ({ ...state, modal2Visible }));
    }

    function handleSelectChange(selectValue) {
        setState(state => ({ ...state, operations: selectValue }))

        return selectValue;
    }

    const showDrawer = (sinistre) => {
        setState(state => ({
            ...state,
            visible: true,
            currentData: sinistre
        }));
    };

    const onClose = () => {
        setState(state => ({
            ...state,
            visible: false,
        }));
    };

    function handleAddClose() {
        setState(state => ({ ...state, isAddDrawerVisible: false }))
    }

    const toggleBackdrop = () => {
        setState(state => ({ ...state, openBackdrop: !state.openBackdrop }))
    }

    const colSize = useMediaQuery();

    return (
        <>
            {/* {state.isLoading ? ( */}
            <Fragment>

                <Card>
                    <div className="container-fluid">
                        <h2>Liste des sinistres</h2><br />
                        <div className="float-left">
                        </div>
                    </div>

                    <Divider />
                    <>
                        <Backdrop className={classes.backdrop} open={state.openBackdrop} onClick={handleClose}>
                            <div>
                                <div style={{ textAlign: "center", fonntSize: 40 }}>
                                    <CircularProgress color="inherit" />
                                </div>

                                <br />

                                <p style={{ fontSize: 40 }}>Chargement du fichier en cours...</p>
                            </div>
                        </Backdrop>
                    </>

                    <div>
                        <Tabs defaultActiveKey="1">
                            {(localStorage.getItem('role') === "ROLE_COMPAGNIE") ? (
                                <>
                                    <TabPane tab="Sinistre émis" key="1">
                                        <div className="container-fluid">
                                            <div className="float-left">
                                                <div>
                                                    <Button type="primary" onClick={() => setState(state => ({ ...state, isAddDrawerVisible: true }))}>
                                                        <PlusOutlined style={{ color: "white" }} /> Ajouter un sinistre
                                                    </Button><br></br><br></br>
                                                </div>
                                            </div>
                                            <div className="float-right" style={{ with: "120px", height: "30px", marginTop: -40 }} >
                                                <UploadStep1 toggleBackdrop={toggleBackdrop} />
                                            </div>
                                        </div>
                                        <br />
                                        <br />
                                        {state.sinistreLoad ? (
                                            <Table
                                                // rowSelection={rowSelection}
                                                columns={columns}
                                                dataSource={state.listSinistreCompagnie}
                                                scroll={{ x: 1400 }}
                                            />
                                        ) : (<Spin />)}
                                    </TabPane>
                                    <TabPane tab="Sinistre reçu" key="2">
                                        <>
                                            <Button type="primary" onClick={() => setModal2Visible(true)} disabled={!hasSelected} loading={loading}>
                                                Action
                                            </Button><br />
                                            <span style={{ marginLeft: 8 }}>
                                                {hasSelected ? ` ${selectedRowKeys.length} Ligne selectionnée(s)` : ''}
                                            </span>
                                        </>
                                        {state.sinistreLoad ? (
                                            <Table
                                                rowSelection={rowSelection}
                                                columns={columns1}
                                                dataSource={state.listSinistreAdverse}
                                                scroll={{ x: 1400 }}
                                            />
                                        ) : (<Spin />)}
                                    </TabPane>
                                </>
                            ) : (
                                <>
                                    <TabPane tab="Sinistre" key="1">
                                        {/* <Backdrop className={classes.backdrop} open={open} > */}
                                        <div className="container-fluid">
                                            <div className="float-left">
                                                {/* {(localStorage.getItem('role') === "ROLE_COMPAGNIE") ? (                     */}
                                                <div>
                                                    <Button type="primary" onClick={() => setState(state => ({ ...state, isAddDrawerVisible: true }))}>
                                                        <PlusOutlined style={{ color: "white" }} /> Ajouter un sinistre
                                                    </Button><br></br><br></br>
                                                </div>
                                                {/* ):(
                                                            null
                                                        )} */}
                                            </div>
                                            <div className="float-right" style={{ with: "120px", height: "30px", marginTop: -40 }} >
                                                <UploadStep1 toggleBackdrop={toggleBackdrop} />
                                            </div>
                                        </div>
                                        <br />
                                        <br />
                                        {state.sinistreLoad ? (
                                            <Table
                                                // rowSelection={rowSelection}
                                                columns={columnsAdmin}
                                                dataSource={state.listSinistreAdmin}
                                                scroll={{ x: 1400 }}
                                            />
                                        ) : (<Spin />)}
                                        {/* </Backdrop> */}
                                    </TabPane>
                                </>
                            )}

                        </Tabs>
                    </div>
                </Card>

                {/* =================================================== VALIDATION DE L'ASSURANCE =============================================== */}

                <Modal
                    title="ATTENTION !!!"
                    centered
                    visible={state.modal2Visible}
                    onOk={() => start()}
                    onCancel={() => setModal2Visible(false)}
                    confirmLoading={state.loading}
                >
                    <div>
                        <Form>
                            <Form.Item rules={[{ required: true }]}>
                                <Select defaultValue="Selectionner le type d'operation" onChange={handleSelectChange}>
                                    <Option value={"2"}>Regler</Option>
                                    <Option value={"3"}>Contester</Option>
                                </Select>

                            </Form.Item>
                        </Form>,
                        <div>
                            <input type="file" onChange={(e) => { setState(state => ({ ...state, file: e.target.files[0] })) }} />
                        </div>
                    </div>
                </Modal>

                {/* ====================================================== Drawer  onChange={} =========================================================== */}

                <Drawer
                    //   width={900}
                    width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 900}
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    visible={state.visible}
                >
                    <h4 className="site-description-item-profile-p">Details</h4>
                    <Divider />
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <div style={styles.itemContainerInfos}>
                                <h5>Informations de la compagnie declarante</h5>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={styles.itemContainer}>
                                <span>Code</span> : <span style={{ ...styles.itemValue }}>{state.currentData?.code}</span>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={styles.itemContainer}>
                                <span>Date sinistre</span> : <span style={styles.itemValue}>{state.currentData?.dateSinistre}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Numéro police</span> : <span style={styles.itemValue}>{state.currentData?.numeroPolice}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Nom assuré</span> : <span style={{ ...styles.itemValue }}>{state.currentData?.nomAssure}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Immatriculation assuré</span> : <span style={styles.itemValue}>{state.currentData?.numeroImmatriculationAssure}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Numéro sinistre</span> : <span style={styles.itemValue}>{state.currentData?.numeroSinistre}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Index de ligne</span> : <span style={styles.itemValue}>{state.currentData?.indexLigne}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Index</span> : <span style={styles.itemValue}>{state.currentData?.index}</span>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <div style={styles.itemContainerInfos}>
                                <h5>Informations de la compagnie adverse</h5>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Code</span> : <span style={styles.itemValue}>{state.currentData?.codeAdverse}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Compagnie</span> : <span style={styles.itemValue}>{state.currentData?.compagnieAdverse}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Numéro Police</span> : <span style={styles.itemValue}>{state.currentData?.numeroPoliceAdverse}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Nom assuré</span> : <span style={styles.itemValue}>{state.currentData?.nomAssureAdverse}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Immatriculation</span> : <span style={styles.itemValue}>{state.currentData?.numeroImmatriculationAssureAdverse}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Type recours</span> : <span style={styles.itemValue}>{state.currentData?.typeRecours}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Motif de validation</span> : <span style={styles.itemValue}>{state.currentData?.motifValidation}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Date de validation</span> : <span style={styles.itemValue}>{state.currentData?.dateValidation}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Montant recours</span> : <span style={styles.itemValue}>{state.currentData?.montantRecours} FCFA</span>
                            </div>
                        </Col>
                        {/* <Col span={8}>
                                <div style={styles.itemContainer}>
                                    <span>Année1</span> : <span style={styles.itemValue}>{state.currentData?.annee1}</span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div style={styles.itemContainer}>
                                    <span>Année2</span> : <span style={styles.itemValue}>{state.currentData?.annee2}</span>
                                </div>
                            </Col> */}
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Selection</span> : <span style={styles.itemValue}>{state.currentData?.selection}</span>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <div style={styles.itemContainerInfos}>
                                <h5>Informations payement</h5>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Montant recours</span> : <span style={styles.itemValue}>{state.currentData?.montantRecours}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Montant payé</span> : <span style={styles.itemValue}>{state.currentData?.montantPaye}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Montant restant</span> : <span style={styles.itemValue}>{state.currentData?.montantRest}</span>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div style={styles.itemContainer}>
                                <span>Statut payement</span> : <span style={styles.itemValue}>{state.currentData?.statutPaiement}</span>
                            </div>
                        </Col>
                    </Row>
                    {/*<Divider />
                        <Row>
                        <Col className="gutter-row" span={8}>
                            <span style={{ cursor: "pointer", color: '#1E88E5' }} onClick={(e) => window.open(API_BASE_URL + "/downloadFile/"+ state.currentData.fileName )} ><i className="fa fa-file"></i>Télécharger le fichier</span>{"    "}
                        </Col>
                        </Row> */}

                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
                            Fermer
                        </Button>
                    </div>
                </Drawer>

                {state.isAddDrawerVisible && (
                    <AddForm
                        visible={state.isAddDrawerVisible}
                        close={handleAddClose}
                    />
                )}

            </Fragment>
            {/* ):(<Spin />)} */}
        </>
    )
}

const styles = {
    itemContainer: {
        margin: 7,
        fontSize: 15
    },
    itemContainerInfos: {
        margin: 5,
        fontSize: 15,
        background: "#ececec",
        padding: 7,
    },
    itemValue: {
        fontWeight: 'bold',
    }
}

const mapStateToProps = (state) => {
    return {
        hasRole: state.auth.hasRole
    }
}

export default connect(mapStateToProps)(AgenceList);
