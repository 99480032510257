import React, { useState } from 'react';

import { useHistory } from "react-router-dom";
import { Menu } from 'antd';
import { connect } from "react-redux";
import { CloudOutlined, SettingOutlined, HomeOutlined, StockOutlined, ShoppingCartOutlined, FolderOutlined, LineChartOutlined, WindowsOutlined, AreaChartOutlined, OrderedListOutlined, ApiOutlined, StopOutlined, WarningOutlined, CheckOutlined, FileDoneOutlined } from '@ant-design/icons';
import AddForm from './AddForm';
import { URLS } from "constant"

const rootSubmenuKeys = ['sinistre', 'reclamationRecue', 'reclamationEffectuees', 'contestation', 'setting', 'reporting'];
const { SubMenu } = Menu;

function Sider(props) {
    const { hasRole } = props

    const [state, setState] = useState({
        openKeys: [],
        isAddDrawerVisible: false

    })

    function handleAddClose() {
        setState(state => ({ ...state, isAddDrawerVisible: false }))
    }


    const history = useHistory()

    function handleClick(data) {
        history.push(URLS[data.key])
    }

    const onOpenChange = openKeys => {
        const latestOpenKey = openKeys.find(key => state.openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            setState(state => ({ ...state, openKeys }));
        } else {
            setState(state => ({
                ...state,
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            }));
        }
    };

    return (
        <div>
            <div style={{
                width: "100%",
                background: "#404040",
                color: "white",
                textAlign: "center",
                fontWeight: 700,
                padding: 10,
                fontSize: 17

            }}>
                DASHBOARD
            </div>

            <Menu
                onClick={handleClick}
                style={{ width: "100%" }}
                mode="inline"
                openKeys={state.openKeys}
                onOpenChange={onOpenChange}
            >
                {(localStorage.getItem('role') === "ROLE_ADMIN" || localStorage.getItem('role') === "ROLE_COMPAGNIE") ?
                    (
                        <>
                            {/* <SubMenu key="sinistre" icon={<FileDoneOutlined />} title="Faire une réclamation">
                            <Menu.Item key="sinistre">Déclarer un sinistre</Menu.Item>
                        </SubMenu> */}
                            <SubMenu key="reclamationRecue" icon={<FileDoneOutlined />} title="Réclamations reçues">
                                {/* <Menu.Item key="newAttestation" onClick={() => setState(state => ({...state, isAddDrawerVisible: true}))}>Nouveau sinistre</Menu.Item> */}
                                <Menu.Item key="reclamationRecue">Liste des réclamations reçues</Menu.Item>
                            </SubMenu>
                            <SubMenu key="reclamationEffectuees" icon={<FileDoneOutlined />} title="Réclamations effectuées">
                                <Menu.Item key="reclamationEffectuees">Liste des réclamations effectuées</Menu.Item>
                            </SubMenu>
                            {/* <SubMenu key="contestation" icon={<StopOutlined />} title="Contestations">
                            <Menu.Item key="contestation">Liste des contestations</Menu.Item>
                        </SubMenu> */}

                        </>
                    ) :
                    (
                        null
                    )}

                {(localStorage.getItem('role') === "ROLE_ADMIN") ? (
                    <>
                        <SubMenu key="reporting" icon={<StockOutlined />} title="Reporting">
                            <Menu.Item key="reporting">Reporting</Menu.Item>
                        </SubMenu>
                        <SubMenu key="setting" icon={<SettingOutlined />} title="Paramètres">
                            <Menu.Item key="users">Utilsateurs</Menu.Item>
                            <Menu.Item key="compagnie">Compagnie</Menu.Item>
                        </SubMenu>
                    </>
                ) : (null)}
            </Menu>
            {state.isAddDrawerVisible && (
                <AddForm
                    visible={state.isAddDrawerVisible}
                    close={handleAddClose}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        hasRole: state.auth.hasRole
    }
}

export default connect(mapStateToProps)(Sider)