import { EditOutlined, EyeOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import makeStyles from '@material-ui/styles/makeStyles';
import { Button, Card, Col, Divider, Drawer, Form, Input, message, notification, Row, Select, Spin, Table, Tabs } from 'antd';
import 'antd/dist/antd.css';
import Modal from 'antd/lib/modal/Modal';
import axios from 'axios';
import { ACCESS_TOKEN, API_BASE_URL, CURRENT_USER } from 'constant';
import AddForm from 'components/layout/Dashbord/AddForm';
import UploadStep1 from 'pages/Importation/UploadStep1';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import useMediaQuery from 'use-media-antd-query';
import useHandleSearch from 'hooks/useHandleSearch'
import { Backdrop, CircularProgress } from '@material-ui/core';
import UpdateForm from './UpdateForm';
import TextArea from 'antd/lib/input/TextArea';

const api_upload = API_BASE_URL + '/files/upload';




const { Option } = Select;


const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

const useStyles = makeStyles(theme => ({
    loginBtn1: {
        textAlign: 'right',
        float: 'right',
        display: 'inline-block',
        marginTop: "-15px",
        popvisible: false,
        updateLigne: [],

    },

    loginBtn2: {
        textAlign: 'left',
        float: 'right',
        display: 'inline-block',
        paddingRight: "9px",
        marginTop: "-15px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));



const pageSize = 10
const key = 'updatable';
const { TabPane } = Tabs;

function AgenceList(props) {
    const { hasRole } = props
    const searchInput = useRef()
    const [form] = Form.useForm();
    const [motifSelected, setMotifSelected] = useState();

    const [state, setState] = useState({
        isLoading: false,
        visible: false,
        listSinistreAdmin: [],
        listSinistreCompagnie: [],
        inputValue: "",
        searchInput: "",
        selectedRowKeys: [], // Check here to configure the default column
        loading: false,
        listSinistreAdverse: [],
        modal2Visible: false,
        datasource: [],
        file: '',
        operations: "",
        value: 'value',
        currentData: {},
        sinistreLoad: false,
        isAddDrawerVisible: false,
        selectValue: "",
        listUser: [],
        openBackdrop: false,
        listeDetailsSinistreAttente: {},
        listeDetailsSinistreRegle: {},
        listeDetailsSinistreConteste: {},
        loadingDetailsSinistre: {},
        loadingDetailsSinistreRegle: {},
        loadingDetailsSinistreConteste: {},
        listText: [],
        selectValueCode: "",
        code1: null,
        listCompagnie: [],
        isUpdateDrawerVisible: false,
        currentItem: {},
        modal2Visibles: false,

        //Motifs
        motifs: []
    })

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getAllSinistre();
        // getAllSinistreAdverse();
        getAllCodeCompagnie()
        loadMotifd()
    }, [])

    const loadMotifd=()=> {
        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/motifs',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };

        axios(config)
        .then((response)=> {
            const data = response?.data.data;
            setState(state=> ({...state, motifs: data || []}))
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    const getColumnSearchProps = useHandleSearch()

    const { listeDetailsSinistreAttente, listeDetailsSinistreRegle, listeDetailsSinistreConteste, loadingDetailsSinistre, loadingDetailsSinistreRegle, loadingDetailsSinistreConteste } = state;

    const getAllSinistre = values => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/sinistre/reclamation-recue',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                console.log(1231111111, response)
                const listeSinistresAdverseAttente = response.data.data.sinistreAdverseAttente;
                const listeSinistresAdverseRegle = response.data.data.sinistreAdverseRegle;
                const listeSinistresAdverseConteste = response.data.data.sinistreAdverseConteste;

                setState(state => {
                    let listeDetailsSinistreAttente = {};
                    let listeDetailsSinistreRegle = {};
                    let listeDetailsSinistreConteste = {};
                    let loadingDetailsSinistre = {};
                    let loadingDetailsSinistreRegle = {};
                    let loadingDetailsSinistreConteste = {};

                    for (let item of listeSinistresAdverseAttente) {
                        listeDetailsSinistreAttente[item.code] = [];
                        loadingDetailsSinistre[item.code] = null;
                    }

                    for (let item of listeSinistresAdverseRegle) {
                        listeDetailsSinistreRegle[item.code] = [];
                        loadingDetailsSinistreRegle[item.code] = null;
                    }

                    for (let item of listeSinistresAdverseConteste) {
                        listeDetailsSinistreConteste[item.code] = [];
                        loadingDetailsSinistreConteste[item.code] = null;
                    }

                    return {
                        ...state,
                        sinistreLoad: true,
                        listSinistreAdverse: listeSinistresAdverseAttente?.map(item => ({ ...item, key: item?.code })),
                        listSinistreAdverseRegle: listeSinistresAdverseRegle.map(item => ({ ...item, key: item?.code })),
                        listSinistreAdverseConteste: listeSinistresAdverseConteste?.map(item => ({ ...item, key: item?.code })),
                        listeDetailsSinistreAttente,
                        listeDetailsSinistreRegle,
                        listeDetailsSinistreConteste,
                        loadingDetailsSinistre
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getDetailsSinistre = (codeSinistre) => {
        const token = localStorage.getItem(ACCESS_TOKEN);

        setState(state => ({
            ...state,
            loadingDetailsSinistre: { ...state.loadingDetailsSinistre, [codeSinistre]: true }
        }));

        var config = {
            method: 'get',
            url: `${API_BASE_URL}/sinistre/compagnie-recue/${codeSinistre}`,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };

        axios(config)
            .then(response => {
                console.log(8888888888, response)
                const data = response.data.data;

                setState(state => ({
                    ...state,
                    loadingDetailsSinistre: { ...state.loadingDetailsSinistre, [codeSinistre]: false },
                    listeDetailsSinistreAttente: { ...state.listeDetailsSinistreAttente, [codeSinistre]: data?.attente.map(item => ({ ...item, key: item.id })) },
                    listeDetailsSinistreRegle: { ...state.listeDetailsSinistreRegle, [codeSinistre]: data?.regle.map(item => ({ ...item, key: item.id })) },
                    listeDetailsSinistreConteste: { ...state.listeDetailsSinistreConteste, [codeSinistre]: data?.conteste.map(item => ({ ...item, key: item.id })) },
                }));
            })
            .catch((error) => {
                setState(state => ({
                    ...state,
                    loadingDetailsSinistre: { ...state.loadingDetailsSinistre, [codeSinistre]: false }
                }));
            });
    }

    // Colum admin
    const columnsAdmin = [
        {
            title: 'Date creation',
            width: 200,
            key: '1',
            dataIndex: "createdAt",
        },
        {
            title: 'Code compagnie',
            width: 200, key: 'code',
            dataIndex: "code",
            ...getColumnSearchProps('code'),
        },
        {
            title: 'Réclamation',
            width: 190, key: 'codeAdverse',
            dataIndex: "codeAdverse",
            ...getColumnSearchProps('codeAdverse'),
        },

        {
            title: 'Règlement',
            width: 260, key: 'dateSinistre',
            dataIndex: "dateSinistre",
            ...getColumnSearchProps('dateSinistre'),
        },

        {
            title: 'Contestation',
            width: 260, key: 'dateSinistre',
            dataIndex: "dateSinistre",
            ...getColumnSearchProps('dateSinistre'),
        },

        // {
        //     title: 'Action',
        //     key: '23',
        //     fixed: 'right',
        //     width: 100,
        //     render: (text, sinistre) => (
        //         <>
        //         <EyeOutlined onClick={()=> showDrawer(sinistre)} />
        //         </>
        //     ),
        // },
    ];


    const columnsFirst = [
        {
            title: 'Code compagnie',
            width: 400,
            key: 'code',
            dataIndex: "code",
        },
        {
            title: 'Montant',
            width: 400,
            key: 'montant',
            dataIndex: "montant",
        },
    ];

    // Notification
    const openNotificationWithIcon = (type, title, msg) => {
        notification[type]({
            message: title,
            description: msg,
        });
    };


    // Checkbook
    // console.log("log file" , state.file) // Console pour la selection du fichier

    const start = (e) => {
        form.validateFields().then((values)=> {
            console.log(values)
            if (!state.operations || isNaN(state.operations)) {
                message.warning("Veuillez selectionner le type d'opération !!!");
                return false;
            }
            if (!state.file && state?.operations === "2") {
                message.warning("Veuillez apporter une preuve !!!");
                return false;
            }
            let infoValue = state.operations
            setState(state => ({ ...state, loading: true }));
            const token = localStorage.getItem(ACCESS_TOKEN);
            const validationRequest = {
                listId: selectedRowKeys,
                statut: infoValue,
                motifId: values?.motif,
                motifOther: values?.autreMotif
            }
            const json = JSON.stringify(validationRequest);
            const blob = new Blob([json], {
                type: "application/json"
            })
            let formData = new FormData();
            formData.append('file', state.file);
            formData.append('validationRequest', blob);
    
            setState(state => ({
                ...state, selectedRowKeys: [], loading: false,
            }));
    
            const url = API_BASE_URL + '/sinistre/validation';
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": 'Bearer ' + token
                },
                data: formData
            };
            axios(url, options)
                .then(response => {
                    // console.log(response)
                    setState(state => ({ ...state, isLoading: false }))
                    // console.log("reponse add file: ", response.data)
    
                    openNotificationWithIcon('success', 'Succès', 'Operartion effectuée avec succès !!!')
                    setModal2Visible(false)
                    setState(state => ({ ...state, }))
                    getAllSinistre()
                    window.location.href = "/reclamationRecue"
                })
                .catch(function (error) {
                    setModal2Visible(false)
                    openNotificationWithIcon('error','Infor','Une erreur est survenue, veuillez réessayer plus tard');
                    console.log(error);
                })
        })
        .catch(e=> console.log("Erreur dans les formulaires :", e)) 
        
    };

    // Retification sans preuve
    const startRetification = (e) => {
        if (!state.operations || isNaN(state.operations)) {
            message.warning("Veuillez selectionner le type d'opération !!!");
            return false;
        }
        // if (!state.file) {
        //     message.warning("Veuillez apporter une preuve !!!");
        //     return false;
        // }
        let infoValue = state.operations
        setState(state => ({ ...state, loading: true }));
        const token = localStorage.getItem(ACCESS_TOKEN);
        const validationRequest = {
            listId: selectedRowKeys,
            statut: infoValue
        }
        const json = JSON.stringify(validationRequest);
        const blob = new Blob([json], {
            type: "application/json"
        })
        let formData = new FormData();
        formData.append('file', state.file);
        formData.append('validationRequest', blob);

        setState(state => ({
            ...state,
            selectedRowKeys: [],
            loading: false,
        }));

        // alert(JSON.stringify(validationRequest))

        const url = API_BASE_URL + '/sinistre/validation';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data',
                "Authorization": 'Bearer ' + token
            },
            data: formData
        };
        axios(url, options)
            .then(response => {
                // console.log(response)
                setState(state => ({ ...state, isLoading: false }))
                // console.log("reponse add file: ", response.data)

                openNotificationWithIcon('success', 'Succès', 'Operartion effectuée avec succès !!!')
                setModal2Visible(false)
                setState(state => ({ ...state, }))
                getAllSinistre()
                window.location.href = "/reclamationRecue"
            })
            .catch(function (error) {
                // openNotificationWithIcon('error','Erreur','Erreur cote client !!!');
                console.log(error);
            })
    };

    const onSelectChange = (selectedRowKeys, selectedRows) => {
        // console.log('selectedRowKeys changed: ', selectedRowKeys);
        // console.log('selectedRowKeys changed: ', selectedRows);
        setState(state => ({ ...state, selectedRowKeys }));
    };

    const { loading, selectedRowKeys } = state;

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    // Function validation 

    function setModal2Visible(modal2Visible) {
        setState(state => ({ ...state, modal2Visible }));
    }

    // Function for retour 
    function setModal2Visibles(modal2Visibles) {
        setState(state => ({ ...state, modal2Visibles }));
    }

    function handleSelectChange(selectValue) {
        setState(state => ({ ...state, operations: selectValue }))

        return selectValue;
    }

    const onMotifSelected=(selectValue)=> {
        console.log("Motif selected : ", selectValue)
        setMotifSelected(selectValue);
    }

    const showDrawer = (sinistre) => {
        setState(state => ({
            ...state,
            visible: true,
            currentData: sinistre
        }));
    };

    const onClose = () => {
        setState(state => ({
            ...state,
            visible: false,
        }));
    };

    function handleAddClose() {
        setState(state => ({ ...state, isAddDrawerVisible: false }))
    }

    const expandedRowRender = (donnees) => {
        if (loadingDetailsSinistre[donnees.code] == null) {
            getDetailsSinistre(donnees.code);
        }

        const columnsT1 = [
            {
                title: 'Date création',
                width: 250,
                key: '1',
                dataIndex: "createdAt",
            },
            {
                title: 'Date sinistre',
                width: 200,
                key: '1',
                dataIndex: "dateSinistre",
            },
            {
                title: 'N° sinistre',
                width: 200, key: 'numeroSinistre',
                dataIndex: "numeroSinistre",
                ...getColumnSearchProps('numeroSinistre'),
            },
            {
                title: 'Immatriculation',
                width: 190, key: 'numeroImmatriculationAssure',
                dataIndex: "numeroImmatriculationAssure",
                ...getColumnSearchProps('numeroImmatriculationAssure'),
            },

            {
                title: 'N° police',
                width: 260, key: 'numeroPolice',
                dataIndex: "numeroPolice",
                ...getColumnSearchProps('numeroPolice'),
            },

            {
                title: 'Compagnie adverse',
                width: 260, key: 'compagnieAdverse',
                dataIndex: "compagnieAdverse",
                ...getColumnSearchProps('compagnieAdverse'),
            },
            {
                title: 'N° sinistre adverse',
                width: 260, key: 'numeroSinistreAdverse',
                dataIndex: "numeroSinistreAdverse",
                ...getColumnSearchProps('numeroSinistreAdverse'),
            },
            {
                title: 'Montant recours',
                width: 260, key: 'montantRecours',
                dataIndex: "montantRecours",
                ...getColumnSearchProps('montantRecours'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, reglement) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(reglement)} />
                        {/* <EditOutlined
                            onClick={() => setState(state => ({ ...state, currentItem: reglement, isUpdateDrawerVisible: true }))}
                        /> */}
                    </>
                ),
            },

        ];

        const columnsT2 = [
            {
                title: 'Date validation',
                width: 250,
                key: '1',
                dataIndex: "updatedAt",
            },
            {
                title: 'Date sinistre',
                width: 200,
                key: '1',
                dataIndex: "dateSinistre",
            },
            {
                title: 'N° sinistre',
                width: 200, key: 'numeroSinistre',
                dataIndex: "numeroSinistre",
                ...getColumnSearchProps('numeroSinistre'),
            },
            {
                title: 'Immatriculation',
                width: 190, key: 'numeroImmatriculationAssure',
                dataIndex: "numeroImmatriculationAssure",
                ...getColumnSearchProps('numeroImmatriculationAssure'),
            },

            {
                title: 'N° police',
                width: 260, key: 'numeroPolice',
                dataIndex: "numeroPolice",
                ...getColumnSearchProps('numeroPolice'),
            },

            {
                title: 'Compagnie adverse',
                width: 260, key: 'compagnieAdverse',
                dataIndex: "compagnieAdverse",
                ...getColumnSearchProps('compagnieAdverse'),
            },
            {
                title: 'N° sinistre adverse',
                width: 260, key: 'numeroSinistreAdverse',
                dataIndex: "numeroSinistreAdverse",
                ...getColumnSearchProps('numeroSinistreAdverse'),
            },
            {
                title: 'Montant recours',
                width: 260, key: 'montantRecours',
                dataIndex: "montantRecours",
                ...getColumnSearchProps('montantRecours'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, reglement) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(reglement)} />
                        {/* <EditOutlined
                            onClick={() => setState(state => ({ ...state, currentItem: reglement, isUpdateDrawerVisible: true }))}
                        /> */}
                    </>
                ),
            },

        ];

        const columnsT3 = [
            {
                title: 'Date Contestation',
                width: 250,
                key: '1',
                dataIndex: "updatedAt",
            },
            {
                title: 'Date sinistre',
                width: 200,
                key: '1',
                dataIndex: "dateSinistre",
            },
            {
                title: 'N° sinistre',
                width: 200, key: 'numeroSinistre',
                dataIndex: "numeroSinistre",
                ...getColumnSearchProps('numeroSinistre'),
            },
            {
                title: 'Immatriculation',
                width: 190, key: 'numeroImmatriculationAssure',
                dataIndex: "numeroImmatriculationAssure",
                ...getColumnSearchProps('numeroImmatriculationAssure'),
            },

            {
                title: 'N° police',
                width: 260, key: 'numeroPolice',
                dataIndex: "numeroPolice",
                ...getColumnSearchProps('numeroPolice'),
            },

            {
                title: 'Compagnie adverse',
                width: 260, key: 'compagnieAdverse',
                dataIndex: "compagnieAdverse",
                ...getColumnSearchProps('compagnieAdverse'),
            },
            {
                title: 'N° sinistre adverse',
                width: 260, key: 'numeroSinistreAdverse',
                dataIndex: "numeroSinistreAdverse",
                ...getColumnSearchProps('numeroSinistreAdverse'),
            },
            {
                title: 'Montant recours',
                width: 260, key: 'montantRecours',
                dataIndex: "montantRecours",
                ...getColumnSearchProps('montantRecours'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, reglement) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(reglement)} />
                        {/* <EditOutlined
                            onClick={() => setState(state => ({ ...state, currentItem: reglement, isUpdateDrawerVisible: true }))}
                        /> */}
                    </>
                ),
            },

        ];


        return <>
            <>
                <Button type="primary"
                    onClick={() => setModal2Visible(true)}
                    disabled={!hasSelected} loading={loading}
                >
                    Action
                </Button><br />
                <span style={{ marginLeft: 8 }}>
                    {hasSelected ? ` ${selectedRowKeys.length} Ligne selectionnée(s)` : ''}
                </span>
            </>
            <Table
                rowSelection={rowSelection}
                loading={tab == 1 ? loadingDetailsSinistre[donnees.code] : tab == 2 ? loadingDetailsSinistreRegle[donnees.code] : loadingDetailsSinistreConteste[donnees.code]}
                columns={tab == 1 ? columnsT1 : tab == 2 ? columnsT2 : columnsT3}
                scroll={{ x: 1000 }}
                dataSource={tab == 1 ? listeDetailsSinistreAttente[donnees.code] : tab == 2 ? listeDetailsSinistreRegle[donnees.code] : listeDetailsSinistreConteste[donnees.code]}
                pagination={true}
            // listSinistreAdverseConteste
            />

        </>
    };


    const expandedRowRenderRegler = (donnees) => {
        if (loadingDetailsSinistre[donnees.code] == null) {
            getDetailsSinistre(donnees.code);
        }

        const columnsT1 = [
            {
                title: 'Date création',
                width: 250,
                key: '1',
                dataIndex: "createdAt",
            },
            {
                title: 'Date sinistre',
                width: 200,
                key: '1',
                dataIndex: "dateSinistre",
            },
            {
                title: 'N° sinistre',
                width: 200, key: 'numeroSinistre',
                dataIndex: "numeroSinistre",
                ...getColumnSearchProps('numeroSinistre'),
            },
            {
                title: 'Immatriculation',
                width: 190, key: 'numeroImmatriculationAssure',
                dataIndex: "numeroImmatriculationAssure",
                ...getColumnSearchProps('numeroImmatriculationAssure'),
            },

            {
                title: 'N° police',
                width: 260, key: 'numeroPolice',
                dataIndex: "numeroPolice",
                ...getColumnSearchProps('numeroPolice'),
            },

            {
                title: 'Compagnie adverse',
                width: 260, key: 'compagnieAdverse',
                dataIndex: "compagnieAdverse",
                ...getColumnSearchProps('compagnieAdverse'),
            },
            {
                title: 'N° sinistre adverse',
                width: 260, key: 'numeroSinistreAdverse',
                dataIndex: "numeroSinistreAdverse",
                ...getColumnSearchProps('numeroSinistreAdverse'),
            },
            {
                title: 'Montant recours',
                width: 260, key: 'montantRecours',
                dataIndex: "montantRecours",
                ...getColumnSearchProps('montantRecours'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, reglement) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(reglement)} />
                        {/* <EditOutlined
                            onClick={() => setState(state => ({ ...state, currentItem: reglement, isUpdateDrawerVisible: true }))}
                        /> */}
                    </>
                ),
            },

        ];

        const columnsT2 = [
            {
                title: 'Date validation',
                width: 250,
                key: '1',
                dataIndex: "updatedAt",
            },
            {
                title: 'Date sinistre',
                width: 200,
                key: '1',
                dataIndex: "dateSinistre",
            },
            {
                title: 'N° sinistre',
                width: 200, key: 'numeroSinistre',
                dataIndex: "numeroSinistre",
                ...getColumnSearchProps('numeroSinistre'),
            },
            {
                title: 'Immatriculation',
                width: 190, key: 'numeroImmatriculationAssure',
                dataIndex: "numeroImmatriculationAssure",
                ...getColumnSearchProps('numeroImmatriculationAssure'),
            },

            {
                title: 'N° police',
                width: 260, key: 'numeroPolice',
                dataIndex: "numeroPolice",
                ...getColumnSearchProps('numeroPolice'),
            },

            {
                title: 'Compagnie adverse',
                width: 260, key: 'compagnieAdverse',
                dataIndex: "compagnieAdverse",
                ...getColumnSearchProps('compagnieAdverse'),
            },
            {
                title: 'N° sinistre adverse',
                width: 260, key: 'numeroSinistreAdverse',
                dataIndex: "numeroSinistreAdverse",
                ...getColumnSearchProps('numeroSinistreAdverse'),
            },
            {
                title: 'Montant recours',
                width: 260, key: 'montantRecours',
                dataIndex: "montantRecours",
                ...getColumnSearchProps('montantRecours'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, item) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(item)} />
                        {/* <EditOutlined
                            onClick={() => setState(state => ({ ...state, currentItem: item, isUpdateDrawerVisible: true }))}
                        /> */}
                    </>
                ),
            },

        ];

        const columnsT3 = [
            {
                title: 'Date Contestation',
                width: 250,
                key: '1',
                dataIndex: "updatedAt",
            },
            {
                title: 'Date sinistre',
                width: 200,
                key: '1',
                dataIndex: "dateSinistre",
            },
            {
                title: 'N° sinistre',
                width: 200, key: 'numeroSinistre',
                dataIndex: "numeroSinistre",
                ...getColumnSearchProps('numeroSinistre'),
            },
            {
                title: 'Immatriculation',
                width: 190, key: 'numeroImmatriculationAssure',
                dataIndex: "numeroImmatriculationAssure",
                ...getColumnSearchProps('numeroImmatriculationAssure'),
            },

            {
                title: 'N° police',
                width: 260, key: 'numeroPolice',
                dataIndex: "numeroPolice",
                ...getColumnSearchProps('numeroPolice'),
            },

            {
                title: 'Compagnie adverse',
                width: 260, key: 'compagnieAdverse',
                dataIndex: "compagnieAdverse",
                ...getColumnSearchProps('compagnieAdverse'),
            },
            {
                title: 'N° sinistre adverse',
                width: 260, key: 'numeroSinistreAdverse',
                dataIndex: "numeroSinistreAdverse",
                ...getColumnSearchProps('numeroSinistreAdverse'),
            },
            {
                title: 'Montant recours',
                width: 260, key: 'montantRecours',
                dataIndex: "montantRecours",
                ...getColumnSearchProps('montantRecours'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, item) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(item)} />
                        {/* <EditOutlined
                            onClick={() => setState(state => ({ ...state, currentItem: reglement, isUpdateDrawerVisible: true }))}
                        /> */}
                    </>
                ),
            },

        ];


        return <>
            <>
                <Button type="primary"
                    onClick={() => setModal2Visibles(true)}
                    disabled={!hasSelected} loading={loading}
                >
                    Action
                </Button><br />
                <span style={{ marginLeft: 8 }}>
                    {hasSelected ? ` ${selectedRowKeys.length} Ligne selectionnée(s)` : ''}
                </span>
            </>
            <Table
                rowSelection={rowSelection}
                loading={tab == 1 ? loadingDetailsSinistre[donnees.code] : tab == 2 ? loadingDetailsSinistreRegle[donnees.code] : loadingDetailsSinistreConteste[donnees.code]}
                columns={tab == 1 ? columnsT1 : tab == 2 ? columnsT2 : columnsT3}
                scroll={{ x: 1000 }}
                dataSource={tab == 1 ? listeDetailsSinistreAttente[donnees.code] : tab == 2 ? listeDetailsSinistreRegle[donnees.code] : listeDetailsSinistreConteste[donnees.code]}
                pagination={true}
            // listSinistreAdverseConteste
            />

        </>
    };


    const expandedRowRenderConteste = (donnees) => {
        if (loadingDetailsSinistre[donnees.code] == null) {
            getDetailsSinistre(donnees.code);
        }

        const columnsT1 = [
            {
                title: 'Date création',
                width: 250,
                key: '1',
                dataIndex: "createdAt",
            },
            {
                title: 'Date sinistre',
                width: 200,
                key: '1',
                dataIndex: "dateSinistre",
            },
            {
                title: 'N° sinistre',
                width: 200, key: 'numeroSinistre',
                dataIndex: "numeroSinistre",
                ...getColumnSearchProps('numeroSinistre'),
            },
            {
                title: 'Immatriculation',
                width: 190, key: 'numeroImmatriculationAssure',
                dataIndex: "numeroImmatriculationAssure",
                ...getColumnSearchProps('numeroImmatriculationAssure'),
            },

            {
                title: 'N° police',
                width: 260, key: 'numeroPolice',
                dataIndex: "numeroPolice",
                ...getColumnSearchProps('numeroPolice'),
            },

            {
                title: 'Compagnie adverse',
                width: 260, key: 'compagnieAdverse',
                dataIndex: "compagnieAdverse",
                ...getColumnSearchProps('compagnieAdverse'),
            },
            {
                title: 'N° sinistre adverse',
                width: 260, key: 'numeroSinistreAdverse',
                dataIndex: "numeroSinistreAdverse",
                ...getColumnSearchProps('numeroSinistreAdverse'),
            },
            {
                title: 'Montant recours',
                width: 260, key: 'montantRecours',
                dataIndex: "montantRecours",
                ...getColumnSearchProps('montantRecours'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, item) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(item)} />
                        {/* <EditOutlined
                            onClick={() => setState(state => ({ ...state, currentItem: item.id, isUpdateDrawerVisible: true }))}
                        /> */}
                    </>
                ),
            },

        ];

        const columnsT2 = [
            {
                title: 'Date validation',
                width: 250,
                key: '1',
                dataIndex: "updatedAt",
            },
            {
                title: 'Date sinistre',
                width: 200,
                key: '1',
                dataIndex: "dateSinistre",
            },
            {
                title: 'N° sinistre',
                width: 200, key: 'numeroSinistre',
                dataIndex: "numeroSinistre",
                ...getColumnSearchProps('numeroSinistre'),
            },
            {
                title: 'Immatriculation',
                width: 190, key: 'numeroImmatriculationAssure',
                dataIndex: "numeroImmatriculationAssure",
                ...getColumnSearchProps('numeroImmatriculationAssure'),
            },

            {
                title: 'N° police',
                width: 260, key: 'numeroPolice',
                dataIndex: "numeroPolice",
                ...getColumnSearchProps('numeroPolice'),
            },

            {
                title: 'Compagnie adverse',
                width: 260, key: 'compagnieAdverse',
                dataIndex: "compagnieAdverse",
                ...getColumnSearchProps('compagnieAdverse'),
            },
            {
                title: 'N° sinistre adverse',
                width: 260, key: 'numeroSinistreAdverse',
                dataIndex: "numeroSinistreAdverse",
                ...getColumnSearchProps('numeroSinistreAdverse'),
            },
            {
                title: 'Montant recours',
                width: 260, key: 'montantRecours',
                dataIndex: "montantRecours",
                ...getColumnSearchProps('montantRecours'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, item) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(item)} />
                        {/* <EditOutlined
                            onClick={() => setState(state => ({ ...state, currentItem: item, isUpdateDrawerVisible: true }))}
                        /> */}
                    </>
                ),
            },

        ];

        const columnsT3 = [
            {
                title: 'Date Contestation',
                width: 250,
                key: '1',
                dataIndex: "updatedAt",
            },
            {
                title: 'Date sinistre',
                width: 200,
                key: '1',
                dataIndex: "dateSinistre",
            },
            {
                title: 'N° sinistre',
                width: 200, key: 'numeroSinistre',
                dataIndex: "numeroSinistre",
                ...getColumnSearchProps('numeroSinistre'),
            },
            {
                title: 'Immatriculation',
                width: 190, key: 'numeroImmatriculationAssure',
                dataIndex: "numeroImmatriculationAssure",
                ...getColumnSearchProps('numeroImmatriculationAssure'),
            },

            {
                title: 'N° police',
                width: 260, key: 'numeroPolice',
                dataIndex: "numeroPolice",
                ...getColumnSearchProps('numeroPolice'),
            },

            {
                title: 'Compagnie adverse',
                width: 260, key: 'compagnieAdverse',
                dataIndex: "compagnieAdverse",
                ...getColumnSearchProps('compagnieAdverse'),
            },
            {
                title: 'N° sinistre adverse',
                width: 260, key: 'numeroSinistreAdverse',
                dataIndex: "numeroSinistreAdverse",
                ...getColumnSearchProps('numeroSinistreAdverse'),
            },
            {
                title: 'Montant recours',
                width: 260, key: 'montantRecours',
                dataIndex: "montantRecours",
                ...getColumnSearchProps('montantRecours'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, item) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(item)} />
                        {/* <EditOutlined
                            onClick={() => setState(state => ({ ...state, currentItem: item, isUpdateDrawerVisible: true }))}
                        /> */}
                    </>
                ),
            },

        ];


        return <>
            <>
                <Button type="primary"
                    onClick={() => setModal2Visibles(true)}
                    disabled={!hasSelected} loading={loading}
                >
                    Action
                </Button><br />
                <span style={{ marginLeft: 8 }}>
                    {hasSelected ? ` ${selectedRowKeys.length} Ligne selectionnée(s)` : ''}
                </span>
            </>
            <Table
                rowSelection={rowSelection}
                loading={tab == 1 ? loadingDetailsSinistre[donnees.code] : tab == 2 ? loadingDetailsSinistreRegle[donnees.code] : loadingDetailsSinistreConteste[donnees.code]}
                columns={tab == 1 ? columnsT1 : tab == 2 ? columnsT2 : columnsT3}
                scroll={{ x: 1000 }}
                dataSource={tab == 1 ? listeDetailsSinistreAttente[donnees.code] : tab == 2 ? listeDetailsSinistreRegle[donnees.code] : listeDetailsSinistreConteste[donnees.code]}
                pagination={true}
            // listSinistreAdverseConteste
            />

        </>
    };


    const getDetailsSelectCodeAdmin = (codeSinistre) => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        let code1 = state.code1;
        console.log("code1", code1)
        console.log("code2", codeSinistre)

        setState(state => ({
            ...state,
            // loadingDetailsSinistre: { ...state.loadingDetailsSinistre, [code1]: true },
            loadingDetailsSinistre: { ...state.loadingDetailsSinistre, [codeSinistre]: true }
        }));

        var config = {
            method: 'get',
            url: API_BASE_URL + '/sinistre/compagnie-recue-admin?code1=' + code1 + '&&code2=' + codeSinistre,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };

        axios(config)
            .then(response => {
                console.log(777777, response.data.data)
                const data = response.data.data;

                setState(state => ({
                    ...state,
                    loadingDetailsSinistre: { ...state.loadingDetailsSinistre, [codeSinistre]: false },
                    listeDetailsSinistreAttente: { ...state.listeDetailsSinistreAttente, [codeSinistre]: data?.attente.map(item => ({ ...item, key: item.id })) },
                    listeDetailsSinistreRegle: { ...state.listeDetailsSinistreRegle, [codeSinistre]: data?.regle.map(item => ({ ...item, key: item.id })) },
                    listeDetailsSinistreConteste: { ...state.listeDetailsSinistreConteste, [codeSinistre]: data?.conteste.map(item => ({ ...item, key: item.id })) },
                }));
            })
            .catch((error) => {
                setState(state => ({
                    ...state,
                    loadingDetailsSinistre: { ...state.loadingDetailsSinistre, [codeSinistre]: false }
                }));
            });
    }

    const expandedRowRenderRecue = (donnees) => {
        if (loadingDetailsSinistre[donnees.code] == null) {
            getDetailsSelectCodeAdmin(donnees.code);
        }

        const columns = [
            {
                title: 'Date sinistre',
                width: 200,
                key: '1',
                dataIndex: "dateSinistre",
            },
            {
                title: 'N° sinistre',
                width: 200, key: 'numeroSinistre',
                dataIndex: "numeroSinistre",
                ...getColumnSearchProps('numeroSinistre'),
            },
            {
                title: 'Immatriculation',
                width: 190, key: 'numeroImmatriculationAssure',
                dataIndex: "numeroImmatriculationAssure",
                ...getColumnSearchProps('numeroImmatriculationAssure'),
            },

            {
                title: 'N° police',
                width: 260, key: 'numeroPolice',
                dataIndex: "numeroPolice",
                ...getColumnSearchProps('numeroPolice'),
            },

            {
                title: 'Compagnie adverse',
                width: 260, key: 'compagnieAdverse',
                dataIndex: "compagnieAdverse",
                ...getColumnSearchProps('compagnieAdverse'),
            },
            {
                title: 'N° sinistre adverse',
                width: 260, key: 'numeroSinistreAdverse',
                dataIndex: "numeroSinistreAdverse",
                ...getColumnSearchProps('numeroSinistreAdverse'),
            },
            {
                title: 'Montant recours',
                width: 260, key: 'montantRecours',
                dataIndex: "montantRecours",
                ...getColumnSearchProps('montantRecours'),
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, item) => (
                    <>

                        <EyeOutlined onClick={() => showDrawer(item)} />
                        {/* <EditOutlined
                            style={{ margin: "10px" }}
                            onClick={() => setState(state => ({ ...state, currentItem: item, isUpdateDrawerVisible: true }))}
                        /> */}
                    </>
                ),
            },

        ];

        return <>
            <>
                {/* <Button type="primary"
                    onClick={() => setModal2Visible(true)}
                    disabled={!hasSelected} loading={loading}
                >
                    Action
                </Button><br />
                <span style={{ marginLeft: 8 }}>
                    {hasSelected ? ` ${selectedRowKeys.length} Ligne selectionnée(s)` : ''}
                </span> */}
            </>
            <Table
                // rowSelection={tab == 1 ? rowSelection : null}
                loading={tab == 1 ? loadingDetailsSinistre[donnees.code] : tab == 2 ? loadingDetailsSinistreRegle[donnees.code] : loadingDetailsSinistreConteste[donnees.code]}
                columns={columns}
                scroll={{ x: 1000 }}
                dataSource={tab == 1 ? listeDetailsSinistreAttente[donnees.code] : tab == 2 ? listeDetailsSinistreRegle[donnees.code] : listeDetailsSinistreConteste[donnees.code]}
                pagination={true}
            />

        </>
    };


    function setModal2Visible(modal2Visible) {
        setState(state => ({ ...state, modal2Visible }));
    }

    const colSize = useMediaQuery();

    const [tab, setTab] = useState(1)
    const [code] = useState(1)
    const [code1] = useState(1)

    function callback(key) {
        console.log(key);
        setTab(key);
    }

    const getAllSelectCode = code1 => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        setState(state => ({ ...state, code1: code1 }));
        var config = {
            method: 'get',
            url: `${API_BASE_URL}/sinistre/reclamation-recue/${code1}`,
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                console.log("12-NSIA", response)
                const listeSinistresAdverseAttente = response.data.data.sinistreAdverseAttente;
                const listeSinistresAdverseRegle = response.data.data.sinistreAdverseRegle;
                const listeSinistresAdverseConteste = response.data.data.sinistreAdverseConteste;

                setState(state => {
                    let listeDetailsSinistreAttente = {};
                    let listeDetailsSinistreRegle = {};
                    let listeDetailsSinistreConteste = {};
                    let loadingDetailsSinistre = {};
                    let loadingDetailsSinistreRegle = {};
                    let loadingDetailsSinistreConteste = {};

                    for (let item of listeSinistresAdverseAttente) {
                        listeDetailsSinistreAttente[item.code1] = [];
                        loadingDetailsSinistre[item.code1] = null;
                    }

                    for (let item of listeSinistresAdverseRegle) {
                        listeDetailsSinistreRegle[item.code1] = [];
                        loadingDetailsSinistreRegle[item.code1] = null;
                    }

                    for (let item of listeSinistresAdverseConteste) {
                        listeDetailsSinistreConteste[item.code1] = [];
                        loadingDetailsSinistreConteste[item.code1] = null;
                    }

                    return {
                        ...state,
                        sinistreLoad: true,
                        listSinistreAdverse: listeSinistresAdverseAttente?.map(item => ({ ...item, key: item?.code1 })),
                        listSinistreAdverseRegle: listeSinistresAdverseRegle.map(item => ({ ...item, key: item?.code1 })),
                        listSinistreAdverseConteste: listeSinistresAdverseConteste?.map(item => ({ ...item, key: item?.code1 })),
                        listeDetailsSinistreAttente,
                        listeDetailsSinistreRegle,
                        listeDetailsSinistreConteste,
                        loadingDetailsSinistre
                    }
                })
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getAllCodeCompagnie = values => {

        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/compagnie',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                setState(state => ({
                    ...state,
                    listCompagnie: response.data.data,
                    roleLoad: true,
                }))
                // console.log(1111111111111111111111122222222222, response)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function handleCloseUpdate() {
        setState(state => ({ ...state, isUpdateDrawerVisible: false }))
    }

    // console.log('key --->', tab);

    // console.log('id ligne --->', code);
    // console.log('id ligne code 1 nom assure--->', state.currentItem.id);

    return (
        <>
            {/* {state.isLoading ? ( */}
            <Fragment>

                <Card>
                    <div className="container-fluid">
                        <h2>Liste des réclamations reçues</h2><br />
                    </div>

                    <Divider />
                    <>
                        <Backdrop className={classes.backdrop} open={state.openBackdrop} onClick={handleClose}>
                            <div>
                                <div style={{ textAlign: "center", fonntSize: 40 }}>
                                    <CircularProgress color="inherit" />
                                </div>

                                <br />

                                <p style={{ fontSize: 40 }}>Chargement du fichier en cours...</p>
                            </div>
                        </Backdrop>
                    </>

                    <div>
                        <Tabs defaultActiveKey='1' onChange={callback}>
                            {(localStorage.getItem('role') === "ROLE_COMPAGNIE") ? (
                                <>
                                    <TabPane tab="Sinistres non encore reglés" key='1'>
                                        <br />
                                        {state.sinistreLoad ? (
                                            <Table
                                                columns={columnsFirst}
                                                dataSource={state.listSinistreAdverse.map(item => ({ ...item, key: item?.code }))}
                                                expandable={{ expandedRowRender: expandedRowRender }}
                                            />
                                        ) : (<Spin />)}
                                    </TabPane>
                                    <TabPane tab="Sinistres reglés" key='2' >
                                        <br />
                                        {state.sinistreLoad ? (
                                            <Table
                                                columns={columnsFirst}
                                                dataSource={state.listSinistreAdverseRegle.map(item => ({ ...item, key: item?.code }))}
                                                expandable={{ expandedRowRender: expandedRowRenderRegler }}
                                            />
                                        ) : (<Spin />)}
                                    </TabPane>
                                    <TabPane tab="Sinistres contestés" key='3'>
                                        <br />
                                        {state.sinistreLoad ? (
                                            <Table
                                                columns={columnsFirst}
                                                dataSource={state.listSinistreAdverseConteste.map(item => ({ ...item, key: item?.code }))}
                                                expandable={{ expandedRowRender: expandedRowRenderConteste }}
                                            />
                                        ) : (<Spin />)}
                                    </TabPane>
                                </>
                            ) : (
                                <>

                                    <TabPane tab="Sinistres non encore reglés" key='1'>
                                        <br />
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Veuillez selectionner le code compagnie"
                                            onChange={getAllSelectCode}
                                        >
                                            {state.listCompagnie.map((m, index) => (
                                                <Option value={m.codeCompagnie}>{m.codeCompagnie}</Option>
                                            ))}
                                        </Select><br /><br />
                                        {state.sinistreLoad ? (
                                            <Table
                                                columns={columnsFirst}
                                                dataSource={state.listSinistreAdverse.map(item => ({ ...item, key: item?.code }))}
                                                expandable={{ expandedRowRender: expandedRowRenderRecue }}
                                            />
                                        ) : (
                                            <Table
                                                columns={columnsFirst}
                                                dataSource={state.listText.map(item => ({ ...item, key: item?.code }))}
                                                expandable={{ expandedRowRender: expandedRowRenderRecue }}
                                            />
                                        )}
                                    </TabPane>
                                    <TabPane tab="Sinistres reglés" key='2' >
                                        <br />
                                        {state.sinistreLoad ? (
                                            <Table
                                                columns={columnsFirst}
                                                dataSource={state.listSinistreAdverseRegle.map(item => ({ ...item, key: item?.code }))}
                                                expandable={{ expandedRowRender: expandedRowRenderRecue }}
                                            />
                                        ) : (
                                            <Table
                                                columns={columnsFirst}
                                                dataSource={state.listText.map(item => ({ ...item, key: item?.code }))}
                                                expandable={{ expandedRowRender: expandedRowRenderRecue }}
                                            />
                                        )}
                                    </TabPane>
                                    <TabPane tab="Sinistres contestés" key='3'>
                                        <br />
                                        {state.sinistreLoad ? (
                                            <Table
                                                columns={columnsFirst}
                                                dataSource={state.listSinistreAdverseConteste.map(item => ({ ...item, key: item?.code }))}
                                                expandable={{ expandedRowRender: expandedRowRenderRecue }}
                                            />
                                        ) : (
                                            <Table
                                                columns={columnsFirst}
                                                dataSource={state.listText.map(item => ({ ...item, key: item?.code }))}
                                                expandable={{ expandedRowRender: expandedRowRenderRecue }}
                                            />

                                        )}
                                    </TabPane>
                                </>
                            )}

                        </Tabs>
                    </div>
                </Card>

                {/* =================================================== VALIDATION DE L'ASSURANCE =============================================== */}

                <Modal
                    title="ATTENTIONS !!!"
                    centered
                    visible={state.modal2Visible}
                    onOk={() => start()}
                    okText="Valider"
                    onCancel={() => setModal2Visible(false)} // A REVOIR
                    confirmLoading={state.loading}
                >
                    <div>
                        <Form name="control-hooks" form={form}>
                            <Form.Item
                                name = "operator"
                                rules={[{ required: true }]}
                            >
                                {/* {tab == 1 ? ( */}
                                <Select
                                    defaultValue="Selectionner le type d'operation"
                                    onChange={handleSelectChange}
                                >
                                    <Option value={"2"}>Regler</Option>
                                    <Option value={"3"}>Contester</Option>
                                </Select>
                                {/* ) : ( 
                                    <>
                                        <Select
                                            defaultValue="Selectionner le type d'operation"
                                            onChange={handleSelectChange}
                                        >
                                            <Option value={"1"}>Non reglé</Option>
                                        </Select>
                                    </>
                                {/* )} */}
                            </Form.Item>
                            <Form.Item
                             name = "file"
                            // rules={[{ required: true, message: "Veuillez apporter la preuve" }]}
                            // rules={[{ required: true, message: "Veuillez apporter la preuve " }]}
                            >
                                <Input type="file"
                                    onChange={(e) => { setState(state => ({ ...state, file: e.target.files[0] })) }}
                                />
                            </Form.Item>

                            {state.operations === "3" ? (
                                <div>
                                    <Form.Item
                                        name = "motif"
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            defaultValue="Motif"
                                            onChange={onMotifSelected}
                                        >
                                            {((state.motifs || []).map(motif=> (<Option value={motif?.id}>{motif?.title}</Option>)))}
                                        </Select>
                                    </Form.Item>
                                    {(motifSelected && motifSelected === 1) && 
                                    (<Form.Item
                                        name="autreMotif"
                                        rules={[{ required: true }]}
                                    >
                                        <TextArea placeholder='Veuillez saisir votre motif'></TextArea>
                                    </Form.Item>)}
                                </div>
                            ) : null}
                            
                            
                        </Form>,
                        {/* <div>
                            
                        </div> */}
                    </div>
                </Modal>

                <Modal
                    title="ATTENTION !!!"
                    centered
                    visible={state.modal2Visibles}
                    onOk={() => startRetification()}
                    onCancel={() => setModal2Visibles(false)} // A REVOIR
                    confirmLoading={state.loading}
                >
                    <div>
                        <Form name="control-hooks" form={form}>
                            {/* {tab == 1 ? ( */}
                            <>
                                <Form.Item
                                    rules={[{ required: true }]}
                                >

                                    <>
                                        <Select
                                            defaultValue="Selectionner le type d'operation"
                                            onChange={handleSelectChange}
                                        >
                                            <Option value={"1"}>Non reglé</Option>
                                        </Select>
                                    </>

                                </Form.Item>
                            </>
                            {/* //  ) : ( 
                            <Form.Item>
                                <Input type="file"
                                    onChange={(e) => { setState(state => ({ ...state, file: e.target.files[0] })) }}
                                />
                            </Form.Item>
                            // )} */}
                        </Form>
                        {/* <div>
                            
                        </div> */}
                    </div>
                </Modal>

                {/* ====================================================== Drawer  onChange={} =========================================================== */}

                <Drawer
                    //   width={900}
                    width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 900}
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    visible={state.visible}
                >
                    <h4 className="site-description-item-profile-p">Details</h4>
                    <Divider />
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <div style={styles.itemContainerInfos}>
                                <h5>Informations de la compagnie declarante</h5>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Code</span> : <span style={{ ...styles.itemValue }}>{state.currentData?.code}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Date sinistre</span> : <span style={styles.itemValue}>{state.currentData?.dateSinistre}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Numéro police</span> : <span style={styles.itemValue}>{state.currentData?.numeroPolice}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Nom assuré</span> : <span style={{ ...styles.itemValue }}>{state.currentData?.nomAssure}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Immatriculation assuré</span> : <span style={styles.itemValue}>{state.currentData?.numeroImmatriculationAssure}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Numéro sinistre</span> : <span style={styles.itemValue}>{state.currentData?.numeroSinistre}</span>
                            </div>
                        </Col>
                        {/* <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Index de ligne</span> : <span style={styles.itemValue}>{state.currentData?.indexLigne}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Index</span> : <span style={styles.itemValue}>{state.currentData?.index}</span>
                            </div>
                        </Col> */}
                    </Row>
                    <Divider />
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <div style={styles.itemContainerInfos}>
                                <h5>Informations de la compagnie adverse</h5>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Code</span> : <span style={styles.itemValue}>{state.currentData?.codeAdverse}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Compagnie</span> : <span style={styles.itemValue}>{state.currentData?.compagnieAdverse}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Numéro Police</span> : <span style={styles.itemValue}>{state.currentData?.numeroPoliceAdverse}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Nom assuré</span> : <span style={styles.itemValue}>{state.currentData?.nomAssureAdverse}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Immatriculation</span> : <span style={styles.itemValue}>{state.currentData?.numeroImmatriculationAssureAdverse}</span>
                            </div>
                        </Col>
                        {/* <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Type recours</span> : <span style={styles.itemValue}>{state.currentData?.typeRecours}</span>
                            </div>
                        </Col> */}
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Date de validation</span> : <span style={styles.itemValue}>{state.currentData?.dateValidation}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Montant recours</span> : <span style={styles.itemValue}>{state.currentData?.montantRecours} FCFA</span>
                            </div>
                        </Col>
                        {/* <Col span={8}>
                                <div style={styles.itemContainer}>
                                    <span>Année1</span> : <span style={styles.itemValue}>{state.currentData?.annee1}</span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div style={styles.itemContainer}>
                                    <span>Année2</span> : <span style={styles.itemValue}>{state.currentData?.annee2}</span>
                                </div>
                            </Col> */}
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Selection</span> : <span style={styles.itemValue}>{state.currentData?.selection}</span>
                            </div>
                        </Col>
                        <Col span={16}>
                            <div style={styles.itemContainer}>
                                <span>Motif de validation</span> : <span style={styles.itemValue}>{state.currentData?.motifValidation}</span>
                            </div>
                        </Col>
                        <Col span={16}>
                            <div style={styles.itemContainer}>
                                <span>Motif de contestation</span> : <span style={styles.itemValue}>{state.currentData?.motif}</span>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <div style={styles.itemContainerInfos}>
                                <h5>Informations payement</h5>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Montant recours</span> : <span style={styles.itemValue}>{state.currentData?.montantRecours}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Montant payé</span> : <span style={styles.itemValue}>{state.currentData?.montantPaye}</span>
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={styles.itemContainer}>
                                <span>Montant restant</span> : <span style={styles.itemValue}>{state.currentData?.montantRest}</span>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div style={styles.itemContainer}>
                                <span>Statut payement</span> : <span style={styles.itemValue}>{state.currentData?.statutPaiement}</span>
                            </div>
                        </Col>
                    </Row><br />
                    <Divider />
                    <Row gutter={[24, 24]}>
                        <Col className="gutter-row" span={12}>
                            <p>Preuve compagnie declarante</p>
                            {state.currentData.fileName ? (
                                <>
                                    <span style={{ cursor: "pointer", color: '#1E88E5' }} onClick={(e) => window.open(API_BASE_URL + "/files/downloadFile/" + state.currentData.fileName)} ><i className="fa fa-file"></i>Télécharger le fichier</span>{"    "}
                                </>
                            ) : <>---</>}

                        </Col>
                        <Col className="gutter-row right" span={12}>
                            <p>Preuve compagnie adverse</p>
                            {state.currentData.adverseFileName ? (
                                <>
                                    <span style={{ cursor: "pointer", color: '#1E88E5' }} onClick={(e) => window.open(API_BASE_URL + "/files/downloadFile/" + state.currentData.adverseFileName)} ><i className="fa fa-file"></i>Télécharger le fichier</span>{"    "}
                                </>
                            ) : <>---</>}
                        </Col>
                    </Row><br /><br />
                    {/*  <Row>
                        <Col className="gutter-row" span={8}>
                            <span style={{ cursor: "pointer", color: '#1E88E5' }} onClick={(e) => window.open(API_BASE_URL + "/downloadFile/"+ state.currentData.fileName )} ><i className="fa fa-file"></i>Télécharger le fichier</span>{"    "}
                        </Col>
                        </Row> */}

                    <div
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            borderTop: '1px solid #e9e9e9',
                            padding: '10px 16px',
                            background: '#fff',
                            textAlign: 'right',
                        }}
                    >
                        <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
                            Fermer
                        </Button>
                    </div>
                </Drawer>

                {state.isAddDrawerVisible && (
                    <AddForm
                        visible={state.isAddDrawerVisible}
                        close={handleAddClose}
                    />
                )}

                {state.isUpdateDrawerVisible && (
                    <UpdateForm
                        visible={state.isUpdateDrawerVisible}
                        currentItem={state.currentItem}
                        close={handleCloseUpdate}

                    />
                )}

            </Fragment>
            {/* ):(<Spin />)} */}
        </>
    )
}

const styles = {
    itemContainer: {
        margin: 7,
        fontSize: 15
    },
    itemContainerInfos: {
        margin: 5,
        fontSize: 15,
        background: "#ececec",
        padding: 7,
    },
    itemValue: {
        fontWeight: 'bold',
    }
}

const mapStateToProps = (state) => {
    return {
        hasRole: state.auth.hasRole
    }
}

export default connect(mapStateToProps)(AgenceList);