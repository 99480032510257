import {API_BASE_URL} from 'constant'
import store from 'redux/store'

export function createTeam(data){
  const url = `${API_BASE_URL}/teams`;
  const token = store.getState().auth.token

  return (
    fetch(url, {
      method:"POST",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body:JSON.stringify(data)
    })
  )
}

export function getTeams(){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/teams`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getUserTeams(){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/teams/filter`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getAgenceTeams(agenceId){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/agences/${agenceId}/teams`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function getTeam(id){
  const token = store.getState().auth.token
  const url = `${API_BASE_URL}/teams/${id}/find`;

  return (
    fetch(url,{
      method:"GET",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
    })
  )
}

export function updateTeam(data){
  const url = `${API_BASE_URL}/teams`;
  const token = store.getState().auth.token

  return (
    fetch(url, {
      method:"PUT",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body:JSON.stringify(data)
    })
  )
}

export function deleteTeam(data){
  const url = `${API_BASE_URL}/teams/${data.id}`;
  const token = store.getState().auth.token

  return (
    fetch(url, {
      method:"DELETE",
      headers:{
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body:JSON.stringify(data)
    })
  )
}

