import React, { Fragment, useState, useEffect, } from 'react';

import { connect } from "react-redux";
import makeStyles from '@material-ui/styles/makeStyles';
import { Card, Table, Input, Button, Divider, Spin, Drawer, Select, Menu, Badge, notification, Tabs, Col, Row, Dropdown, Space, Tag } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined, EyeOutlined, FileOutlined, DownOutlined } from '@ant-design/icons';

import axios from 'axios';
import Modal from 'antd/lib/modal/Modal';
import TextArea from 'antd/lib/input/TextArea';
import 'antd/dist/antd.css';
import { ACCESS_TOKEN, API_BASE_URL, CURRENT_USER } from 'constant';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import useMediaQuery from 'use-media-antd-query';
import useHandleSearch from 'hooks/useHandleSearch'



const menu = (
    <Menu>
        <Menu.Item>Action 1</Menu.Item>
        <Menu.Item>Action 2</Menu.Item>
    </Menu>
);

const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
        <p className="site-description-item-profile-p-label">{title}:</p>
        {content}
    </div>
);

const { Option } = Select;
const { confirm } = Modal;
const useStyles = makeStyles(theme => ({
    loginBtn1: {
        textAlign: 'right',
        float: 'right',
        display: 'inline-block',
        marginTop: "-15px",
        popvisible: false,
        updateLigne: [],

    },

    loginBtn2: {
        textAlign: 'left',
        float: 'right',
        display: 'inline-block',
        paddingRight: "9px",
        marginTop: "-15px",
    },
    site: {
        marginBottom: "7px",
        color: "rgba(0, 0, 0, 0.65)",
        fontSize: "14px",
        lineHeight: "1.5715",
    },

    site_description_item_profile_wrapper: {
        fontSize: "12px",
        lineHeight: "1.66667",
    },

    ant_drawer_body_site_description_item: {
        display: "block",
        marginBottom: "16px",
        color: "rgba(0, 0, 0, 0.85)",
        fontSize: "16px",
        lineHeight: "1.5715",
    },

    ant_drawer_body_site_description_item_profile_p: {
        fontSize: "14px",
        lineHeight: "1.66667",
    },

    site_description_item_profile_p_label: {
        display: "inline-block",
        marginRight: "8px",
        color: "rgba(0, 0, 0, 0.85)"
    }
}));
const pageSize = 10
const key = 'updatable';
const { TabPane } = Tabs;

function TeamList(props) {
    const { hasRole } = props

    const [state, setState] = useState({
        listAttente: [],
        listAdverse: [],
        isLoading: false,
        isLoad: false,
        visible: false,
        listSinistre: [],
        Liste: [],
        articleForSelect: [],
        searchInput: '',
        inputValue: '',
        reglementLoad: false,
        listReglementConteste: [],
        currentData: {},
        listCompagnie: [],
        listDetailsTable: [],
        isLoggedIn: false,
        listAdmin: []

    })

    const [page, setPage] = useState(1);

    const classes = useStyles()
    const history = useHistory();

    useEffect(() => {
        getAllMesReglement();
    }, [])


    const getAllMesReglement = values => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        var config = {
            method: 'get',
            url: API_BASE_URL + '/sinistre/reglement',
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "Authorization": 'Bearer ' + token
            },
        };
        axios(config)
            .then(response => {
                setState(state => ({
                    ...state,
                    reglementLoad: true,
                    listCompagnie: response.data.data?.reglementCompagnie,
                    listAdverse: response.data.data?.reglementAdverse,
                    listAdmin: response.data.data?.reglementAdmin,

                }))
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const getColumnSearchProps = useHandleSearch()

    // Details table
    const expandedRowRender = (donnees) => {
        const columns = [
            {
                title: 'Date sinistre',
                width: 90,
                key: 'dateSinistre',
                dataIndex: "dateSinistre",
            },
            {
                title: 'Numero police',
                width: 100,
                key: 'numeroPoliceAdverse',
                dataIndex: "numeroPoliceAdverse",
            },
            {
                title: 'Code compagnie',
                width: 90,
                key: 'codeAdverse',
                dataIndex: "codeAdverse",
                ...getColumnSearchProps('codeAdverse'),

            },
            {
                title: 'Immatriculation',
                width: 90,
                key: 'numeroImmatriculationAssureAdverse',
                dataIndex: "numeroImmatriculationAssureAdverse",
            },
            {
                title: 'Nom assuré',
                width: 100,
                key: 'nomAssureAdverse',
                dataIndex: "nomAssureAdverse",
                ...getColumnSearchProps('nomAssureAdverse'),
            },
            {
                title: 'Type recours',
                width: 100,
                key: 'typeRecours',
                dataIndex: "typeRecours",
            },
            {
                title: 'Action',
                key: 'action',
                width: 90,
                render: (text, reglement) => (
                    <>
                        <EyeOutlined onClick={() => showDrawer(reglement)} />
                    </>
                ),
            },

        ];

        return <Table columns={columns} scroll={{ x: 1000 }} dataSource={donnees.listSinistre} pagination={false} />;
    };


    const { article } = state

    // Declancheur de modal

    // Column Admin
    const columnsAdmin = [
        {
            title: 'Date validation',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.createdAt}
                </div>
            )
        },
        {
            title: 'Compagnie',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.nomCompagnie}
                </div>
            )
        },
        {
            title: 'Type opération',
            width: 400,
            key: 'typeRegler',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.typeRegler}
                </div>
            ),

        },
        {
            title: 'Statut',
            key: 'statut',
            width: 400,
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.statut == 2 ? <Tag color="green">Regler</Tag> : <Tag color="red">Contester</Tag>}
                </div>
            )
        },
    ];

    //   Compagnie adverse
    const columns = [
        {
            title: 'Date validation',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.createdAt}
                </div>
            )
        },
        {
            title: 'Compagnie',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.nomCompagnieAdverse}
                </div>
            )
        },
        {
            title: 'Type opération',
            width: 400,
            key: 'typeRegler',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.typeRegler}
                </div>
            ),

        },
        {
            title: 'Statut',
            key: 'statut',
            width: 400,
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.statut == 2 ? <Tag color="green">Regler</Tag> : <Tag color="red">Contester</Tag>}
                </div>
            )
        },

    ];

    // Compagnie declarante
    const columns1 = [
        {
            title: 'Date validation',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.createdAt}
                </div>
            )
        },
        {
            title: 'Compagnie',
            width: 400,
            key: 'createdAt',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.nomCompagnie}
                </div>
            )
        },
        {
            title: 'Type opération',
            width: 400,
            key: 'typeRegler',
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.typeRegler}
                </div>
            ),

        },
        {
            title: 'Statut',
            key: 'statut',
            width: 400,
            dataIndex: "validationSinistre",
            render: (validationSinistre) => (
                <div>
                    {validationSinistre?.statut == 2 ? <Tag color="green">Regler</Tag> : <Tag color="red">Contester</Tag>}
                </div>
            )
        },
    ];


    const showDrawer = (reglement) => {
        setState(state => ({
            ...state,
            visible: true,
            currentData: reglement
        }));
    };

    const onClose = () => {
        setState(state => ({
            ...state,
            visible: false,
        }));
    };

    const colSize = useMediaQuery();


    const fileName = state.currentData;

    return (
        <Fragment>
            <Card>
                <div className="container-fluid">
                    <h2>Liste des règlements</h2><br />
                </div>

                <Divider />

                <div>
                    {(localStorage.getItem('role') === "ROLE_COMPAGNIE") ? (
                        <>
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="Règlement émis" key="1">
                                    {state.reglementLoad ? (
                                        <Table
                                            className="components-table-demo-nested"
                                            dataSource={state.listCompagnie.map(item => ({ ...item, key: item.validationSinistre?.id }))}
                                            columns={columns1}
                                            // scroll={{ x: 2000}}
                                            expandable={{ expandedRowRender }}
                                        />
                                    ) : (<Spin />)}
                                </TabPane>
                                <TabPane tab="Règlement reçu" key="2">
                                    {state.reglementLoad ? (
                                        <Table
                                            dataSource={state.listAdverse.map(item => ({ ...item, key: item.validationSinistre?.id }))}
                                            columns={columns}
                                            // scroll={{ x: 1800}}
                                            expandable={{ expandedRowRender }}

                                        />
                                    ) : (<Spin />)}
                                </TabPane>
                            </Tabs>
                        </>
                    ) : (
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Règlements" key="1">
                                {state.reglementLoad ? (
                                    <Table
                                        className="components-table-demo-nested"
                                        dataSource={state.listAdmin.map(item => ({ ...item, key: item.validationSinistre?.id }))}
                                        columns={columnsAdmin}
                                        // scroll={{ x: 2000}}
                                        expandable={{ expandedRowRender }}
                                    />
                                ) : (<Spin />)}
                            </TabPane>
                        </Tabs>
                    )}
                </div>

            </Card>

            <Drawer
                //   width={900}
                width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 900}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={state.visible}
            >
                <h4 className="site-description-item-profile-p">Details</h4>
                <Divider />
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <div style={styles.itemContainerInfos}>
                            <h5>Informations de la compagnie declarante</h5>
                        </div>
                    </Col>
                    <Col span={10}>
                        <div style={styles.itemContainer}>
                            <span>Code</span> : <span style={{ ...styles.itemValue }}>{state.currentData?.code}</span>
                        </div>
                    </Col>
                    <Col span={10}>
                        <div style={styles.itemContainer}>
                            <span>Date sinistre</span> : <span style={styles.itemValue}>{state.currentData?.dateSinistre}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Numéro police</span> : <span style={styles.itemValue}>{state.currentData?.numeroPolice}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Nom assuré</span> : <span style={{ ...styles.itemValue }}>{state.currentData?.nomAssure}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Immatriculation assuré</span> : <span style={styles.itemValue}>{state.currentData?.numeroImmatriculationAssure}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Numéro sinistre</span> : <span style={styles.itemValue}>{state.currentData?.numeroSinistre}</span>
                        </div>
                    </Col>
                    {/* <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Index de ligne</span> : <span style={styles.itemValue}>{state.currentData?.indexLigne}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Index</span> : <span style={styles.itemValue}>{state.currentData?.index}</span>
                        </div>
                    </Col> */}
                </Row>
                <Divider />
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <div style={styles.itemContainerInfos}>
                            <h5>Informations de la compagnie adverse</h5>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Code</span> : <span style={styles.itemValue}>{state.currentData?.codeAdverse}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Compagnie</span> : <span style={styles.itemValue}>{state.currentData?.compagnieAdverse}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Numéro Police</span> : <span style={styles.itemValue}>{state.currentData?.numeroPoliceAdverse}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Nom assuré</span> : <span style={styles.itemValue}>{state.currentData?.nomAssureAdverse}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Immatriculation</span> : <span style={styles.itemValue}>{state.currentData?.numeroImmatriculationAssureAdverse}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Type recours</span> : <span style={styles.itemValue}>{state.currentData?.typeRecours}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Motif de validation</span> : <span style={styles.itemValue}>{state.currentData?.motifValidation}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Date de validation</span> : <span style={styles.itemValue}>{state.currentData?.dateValidation}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Montant recours</span> : <span style={styles.itemValue}>{state.currentData?.montantRecours} FCFA</span>
                        </div>
                    </Col>
                    {/* <Col span={8}>
                    <div style={styles.itemContainer}>
                        <span>Année1</span> : <span style={styles.itemValue}>{state.currentData?.annee1}</span>
                    </div>
                </Col>
                <Col span={8}>
                    <div style={styles.itemContainer}>
                        <span>Année2</span> : <span style={styles.itemValue}>{state.currentData?.annee2}</span>
                    </div>
                </Col> */}
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Selection</span> : <span style={styles.itemValue}>{state.currentData?.selection}</span>
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <div style={styles.itemContainerInfos}>
                            <h5>Informations payement</h5>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Montant recours</span> : <span style={styles.itemValue}>{state.currentData?.montantRecours}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Montant payé</span> : <span style={styles.itemValue}>{state.currentData?.montantPaye}</span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={styles.itemContainer}>
                            <span>Montant restant</span> : <span style={styles.itemValue}>{state.currentData?.montantRest}</span>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div style={styles.itemContainer}>
                            <span>Statut payement</span> : <span style={styles.itemValue}>{state.currentData?.statutPaiement}</span>
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Row>
                    {state.currentData.fileName ? (
                        <Col className="gutter-row" span={8}>
                            <span style={{ cursor: "pointer", color: '#1E88E5' }} onClick={(e) => window.open(API_BASE_URL + "/files/downloadFile/" + state.currentData.fileName)} ><i className="fa fa-file"></i>Télécharger le fichier</span>{"    "}
                        </Col>
                    ) : (null)}
                </Row><br /><br />

                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={() => onClose()} style={{ marginRight: 8 }}>
                        Fermer
                    </Button>
                </div>
            </Drawer>

        </Fragment>
    )
}

const styles = {
    itemContainer: {
        margin: 7,
        fontSize: 15
    },
    itemValue: {
        fontWeight: 'bold',
    },
    itemContainerInfos: {
        margin: 5,
        fontSize: 15,
        background: "#ececec",
        padding: 7,
    },
}

const mapStateToProps = (state) => {
    return {
        hasRole: state.auth.hasRole
    }
}

export default connect(mapStateToProps)(TeamList);
