import {SIGN_IN, LOG_OUT } from "../actionTypes";
import { USERS_ROLE, ACCESS_TOKEN, CURRENT_USER } from 'constant'

const initialState = {
    token: null,
    user: {},
    isAuthenticated: false,
    hasRole: {
        admin: false,
    }
};

const authReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case SIGN_IN:
            localStorage.setItem(ACCESS_TOKEN, action.token)
            localStorage.setItem(CURRENT_USER, action.user)

            return {
                ...state,
                token: action.token,
                user: action.user,
                isAuthenticated: true,
            };
        case LOG_OUT:
            localStorage.clear()
            return initialState;
        default:
            return state;
    }
}

export default authReducer;