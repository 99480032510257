import axios from 'axios';
import {notification } from 'antd';
const accessToken = localStorage.getItem('accessToken');  
class ApiService{


  /*List CRUD API*/  
    getAll(url){
        return axios.get(url, {headers: { 
            'Authorization': 'Bearer ' + accessToken, 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            }}); 
    }


    create(url, body){
        return axios.post(url, JSON.stringify(body), {headers: { 
            'Authorization': 'Bearer ' + accessToken, 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            }});
    }


    update(url, body){
        return axios.put(url, JSON.stringify(body), {headers: { 
            'Authorization': 'Bearer ' + accessToken, 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            }});
    }


    getOne(url){
        return axios.get(url, {headers: { 
            'Authorization': 'Bearer ' + accessToken, 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            }});
    }
    

    deleteById(url){
        return axios.delete(url, {headers: { 
            'Authorization': 'Bearer ' + accessToken, 
            'Content-Type': 'application/json',
            'Accept' : 'application/json',
            }});
    }


  /*List notification*/  
saveNotification(msg){
    notification.success({
        message: 'Succès',
        description: msg
      });
}

editNotification(msg){
    notification.success({
        message: 'Succès',
        description: msg
      });
}

deleteNotification(msg){
    notification.success({
        message: 'Succès',
        description: msg
      });
}

errorNotification(msg){
    notification.error({
        message: 'Erreur',
        description: msg!=null?msg:"Erreur de validation"
      });
}

errorDeleteNotification(msg){
    notification.error({
        message: 'Erreur',
        description: msg
      });
}
     
    

}
export default new ApiService();