import React, { useState, useEffect } from 'react';

import useMediaQuery from 'use-media-antd-query';
import { Drawer, Form, Select, Button, Col, Row, Input, Spin, Divider, notification, DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import { ACCESS_TOKEN, API_BASE_URL, GET_USERS_FILES_CTR, STATUS_CODE_REST_API } from 'constant';
import { exeRequestFinal, exeRequest } from 'util/APIUtils';
import axios from 'axios';
import { getUserAsaci } from 'services/user';
import TextArea from 'antd/lib/input/TextArea';
import NumberFormat from 'react-number-format';
import InputNumberFormatFr from 'components/layout/InputNumberFormatFr/InputNumberFormatFr';

const { Option } = Select;
const dateFormat = "DD-MM-YYYY"


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function UpdateForm(props) {
  const { currentItem } = props

  const [state, setState] = useState({
    loading: false,
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [{
      url: GET_USERS_FILES_CTR + currentItem.username + ".png",
      uid: props.currentItem.id,
      name: 'image.png',
      status: 'done'
    }],
    roles: [],
    isUpdateDrawerVisible: false,
    roleLoad: false,
    currentUser: {},
    listUsers: [],
    listeDetailsSinistreAttente: {},
    listeDetailsSinistreRegle: {},
    listeDetailsSinistreConteste: {},
    loadingDetailsSinistre: {},
    loadingDetailsSinistreRegle: {},
    loadingDetailsSinistreConteste: {},
    isLoadingCompagnie: false,
    list: [],
    codeCompagnie: props.currentItem.codeCompagnie,
    selectedRowKeys: [],
    updateFile: '',
    isLoading: false

  })

  const [form] = Form.useForm();

  useEffect(() => {
    getAllSinistre();
    getDetailsSinistre();
    getAllSinistreById();
    getAllCompagnie();
    setState(
      state => ({ ...state, isLoading: true }));
  },

    [])

  function handleClose(user) {
    props.close(user);
  }

  function handleCloseUpdate() {
    setState(state => ({ ...state, isUpdateDrawerVisible: false }))
  }

  // Notification
  const openNotificationWithIcon = (type, title, msg) => {
    notification[type]({
      message: title,
      description: msg,
    });
  };

  const { listeDetailsSinistreAttente, listeDetailsSinistreRegle, listeDetailsSinistreConteste, loadingDetailsSinistre, loadingDetailsSinistreRegle, loadingDetailsSinistreConteste } = state;



  function getAllSinistre() {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/sinistre/liste',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(res => {
        console.log("TEST GET ALL SINISTRE ====================>>>>>>>>>>>>>>>>>>>", res)
        setState(state => ({
          ...state,
          isLoading: false,
          listAllSinistre: res.data
        }));
      });
  }

  const getDetailsSinistre = (codeSinistre) => {
    const token = localStorage.getItem(ACCESS_TOKEN);

    setState(state => ({
      ...state,
      loadingDetailsSinistre: { ...state.loadingDetailsSinistre, [codeSinistre]: true }
    }));

    var config = {
      method: 'get',
      url: `${API_BASE_URL}/sinistre/compagnie-recue/${codeSinistre}`,
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };

    axios(config)
      .then(response => {
        console.log(8888888888, response)
        const data = response.data.data;

        setState(state => ({
          ...state,
          loadingDetailsSinistre: { ...state.loadingDetailsSinistre, [codeSinistre]: false },
          listeDetailsSinistreAttente: { ...state.listeDetailsSinistreAttente, [codeSinistre]: data?.attente.map(item => ({ ...item, key: item.id })) },
          listeDetailsSinistreRegle: { ...state.listeDetailsSinistreRegle, [codeSinistre]: data?.regle.map(item => ({ ...item, key: item.id })) },
          listeDetailsSinistreConteste: { ...state.listeDetailsSinistreConteste, [codeSinistre]: data?.conteste.map(item => ({ ...item, key: item.id })) },
        }));
      })
      .catch((error) => {
        setState(state => ({
          ...state,
          loadingDetailsSinistre: { ...state.loadingDetailsSinistre, [codeSinistre]: false }
        }));
      });
  }


  const onFinish = (values) => {
    console.log(values)
    let sinistre = {
      code: values.code,
      codeCompagnie: values.codeCompagnie,
      compagnieAdverse: values.compagnieAdverse,
      // codeEqupe: { id: values.codeEqupe },
      numeroImmatriculationAssure: values.numeroImmatriculationAssure,
      numeroImmatriculationAssureAdverse: values.numeroImmatriculationAssureAdverse,
      numeroPolice: values.numeroPolice,
      numeroPoliceAdverse: values.numeroPoliceAdverse,
      montantRecours: values.montantRecours,
      nomAssure: values.nomAssure,
      nomAssureAdverse: values.nomAssureAdverse,
      nomAssure: values.nomAssure,
      numeroSinistre: values.numeroSinistre,
      dateSinistre: values.dateSinistre,
      codeAdverse: state.codeCompagnie,
      // typeRecours: values.typeRecours,
      motifValidation: values.motifValidation,
      numeroSinistreAdverse: values.numeroSinistreAdverse,
      dateValidation: values.dateValidation,
    }
    setState(state => ({ ...state, loading: true }));

    const json = JSON.stringify(sinistre);
    const blob = new Blob([json], {
      type: 'application/json'
    });
    let formData = new FormData();
    formData.append('sinistre', blob)
    formData.append('file', state.updateFile);

    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'PUT',
      url: API_BASE_URL + '/sinistre/upadte/' + currentItem.id,
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
      data: formData,
    };
    // console.log("CONTENU MODIFICATION ==========================>>>>>>>>>>>>>>>>>", config.data)

    axios(config)
      .then(response => {
        // console.log("Update sinistre ============================>>>>>>>>>>>>> ", response.data)
        setState(state => ({ ...state, isLoading: false }))
        getAllSinistre()
        openNotificationWithIcon('success', 'Succès', 'Modification effectuée avec succès !!!');
        handleClose()
        // window.location.reload()
      })
      .catch(function (error) {
        openNotificationWithIcon('error', 'Erreur', 'Une erreur est survenue lors de la modification !!!');
        handleClose()
        getAllSinistre()
      });
  }

  function getAllCompagnie() {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + "/compagnie",
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(res => {
        setState(state => ({
          ...state,
          isLoadingCompagnie: true,
          list: res.data.data
        }));
        // console.log("compagnie end=================>", res)
      });
  }

  function onChangeCodeCompagnie(value) {
    console.log("1234 =========>>>>>>>>", value)
    setState(state => ({ ...state, codeCompagnie: value }))
  }


  const colSize = useMediaQuery();

  const getAllRole = values => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/role',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(response => {
        setState(state => ({
          ...state,
          roles: response.data,
          roleLoad: true,
        }))
        console.log("reponse get role update: ", response.data)

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // Get all user
  const getAllSinistreById = values => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    var config = {
      method: 'get',
      url: API_BASE_URL + '/sinistre/liste',
      headers: {
        "Accept": "application/json",
        "Content-type": "application/json",
        "Authorization": 'Bearer ' + token
      },
    };
    axios(config)
      .then(response => {
        // console.log("GET ALL SINISTRE ==================>>>>>>>>>>>>>>>>>>>>", response)
        setState(state => ({
          ...state,
          listUsers: response.data.data,
          roleLoad: true,
        }))
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const getUpdateFile = (e) => {
    let file = e.target.files[0];
    // console.log('update file: ---> ', file);
    setState(state => ({ ...state, updateFile: file }));
  }

  return (
    <>
      <Drawer
        title={"Modification d'un sinistre"}
        width={colSize == "xs" ? 300 : colSize == "sm" ? 400 : 900}
        onClose={() => handleCloseUpdate()}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
      >

        <Form layout="vertical" hideRequiredMark form={form} name="control-hooks" onFinish={onFinish} autoComplete="off">
          <Row gutter={[24, 24]}>
            <Col span={4}>
              <Form.Item name="id" initialValue={currentItem.id}>
                <Input type="hidden" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="code" initialValue={props.currentItem.code}>
                <Input type="hidden" />
              </Form.Item>
            </Col>
            <Col span={4}></Col>
            <Col span={4}></Col>
          </Row>

          {/* <Form.Item name="montantRecours" initialValue={currentItem.montantRecours}>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="id" initialValue={currentItem.id}>
            <Input type="hidden" />
          </Form.Item> */}

          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div style={styles.itemContainerInfos}>
                <h5>Informations de la compagnie declarante</h5>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroPolice"
                id="numeroPolice"
                label="Numéro de police"
                // rules={[{ required: true, message: "Veuillez saisir le numero de police" }]}
                initialValue={props.currentItem.numeroPolice}
              >
                <Input placeholder="Veuillez saisir le numero de police" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="montantRecours"
                id="montantRecours"
                label="Montant"
                // rules={[{ required: true, message: "Veuillez saisir le numero de police" }]}
                initialValue={props.currentItem.montantRecours}
              >
                <InputNumberFormatFr
                  value={props.currentItem.montantRecours}
                  displayType={"text"}
                  thousandSeparator={" "}
                />
                {/* <Input placeholder="Veuillez saisir le montant recours" /> */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="nomAssure"
                id="nomAssure"
                label="Nom assuré"
                initialValue={props.currentItem.nomAssure}
              // rules={[{ required: true, message: "Veuillez saisir le nom assure" }]}
              >
                <Input placeholder="Veuillez saisir le nom assure" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroImmatriculationAssure"
                id="numeroImmatriculationAssure"
                label="Immatriculation"
                initialValue={props.currentItem.numeroImmatriculationAssure}
              // rules={[{ required: true, message: "Veuillez saisir l'immatriculation" }]}
              >
                <Input placeholder="Veuillez saisir l'immatriculation" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="dateSinistre"
                id="dateSinistre"
                label="Date sinistre"
                // rules={[{ required: true, message: 'Veuillez selection la date' }]}
                htmlFor="dateSinistre"
                initialValue={props.currentItem.dateSinistre}
              >
                <Input placeholder='Saisissez la date'
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroSinistre"
                id="numeroSinistre"
                label="Numero sinistre"
                initialValue={props.currentItem.numeroSinistre}
              // rules={[{ required: true, message: "Veuillez saisir le numero de sinistre" }]}
              >
                <Input placeholder="Veuillez saisir le numero sinistre" />
              </Form.Item>
            </Col>

          </Row>
          <Divider />
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div style={styles.itemContainerInfos}>
                <h5>Informations de la compagnie adverse</h5>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item
                name="nomCompagnie"
                id="nomCompagnie"
                label="Compagnie"
                initialValue={props.currentItem.compagnieAdverse}
              // rules={[{ required: true, message: 'Veuillez selectionner la compagnie' }]}
              >
                {state.isLoadingCompagnie ? (
                  <Select initialValue={props.currentItem.compagnieAdverse} placeholder="Veuillez selectionner la compagnie" onChange={onChangeCodeCompagnie} disabled={true}>
                    {state.list.map((m, index) => (
                      <Option value={m.codeCompagnie}>{m.compagnieAdverse}</Option>
                    ))}
                  </Select>
                ) : <Spin />}

              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="nomAssureAdverse"
                id="nomAssureAdverse"
                label="Nom assuré"
                initialValue={props.currentItem.nomAssureAdverse}
              // rules={[{ required: true, message: "Veuillez saisir le nom de l'assure adverse" }]}
              >
                <Input placeholder="Veuillez saisir le nom de l'assure adverse" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroPoliceAdverse"
                id="numeroPoliceAdverse"
                label="Numéro de police"
                initialValue={props.currentItem.numeroPoliceAdverse}
              // rules={[{ required: true, message: "Veuillez saisir le numero de police adverse" }]}
              >
                <Input placeholder="Veuillez saisir le numero de police adverse" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroImmatriculationAssureAdverse"
                id="numeroImmatriculationAssureAdverse"
                label="Immatriculation"
                initialValue={props.currentItem.numeroImmatriculationAssureAdverse}
              // rules={[{ required: true, message: "Veuillez saisir l'immatriculation adverse" }]}
              >
                <Input placeholder="Veuillez saisir l'immatriculation adverse" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="numeroSinistreAdverse"
                id="numeroSinistreAdverse"
                label="Numero sinistre"
                initialValue={props.currentItem.numeroSinistreAdverse}
              // rules={[{ required: true, message: "Veuillez saisir l'immatriculation adverse" }]}
              >
                <Input placeholder="Veuillez saisir le numero adverse" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="dateValidation"
                id="dateValidation"
                label="Date validation"
                initialValue={props.currentItem.dateValidation}
              // rules={[{ required: true, message: "Veuillez saisir la date" }]}
              >
                <Input placeholder='Saisissez la date de validation'
                />
              </Form.Item>
            </Col>

          </Row><Divider />
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div style={styles.itemContainerInfos}>
                <h5>Autres informations</h5>
              </div>
            </Col>
            <Col span={24}>
              <Form.Item
                name="motifValidation"
                id="motifValidationId"
                label="Motif de création"
                initialValue={props.currentItem.motifValidation}
              // rules={[{ required: true, message: 'Motif de création' }]}
              // htmlFor=""
              >
                <TextArea rows="4" placeholder="Saisissez le motif de création du sinistre mono" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="file"
                id="file"
                label="Ajouter une preuve"
                initialValue={props.currentItem.file}
                rules={[{ required: true, message: 'Veuillez charger une preuve !!!' }]}
              // htmlFor=""
              >
                <input type="file" onChange={getUpdateFile} />
              </Form.Item>
            </Col>
          </Row>

          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={() => handleClose()} style={{ marginRight: 8 }}>
              Annuler
            </Button>
            <Button
              loading={state.loading}
              htmlType="submit" type="primary"
            >
              Enregistrer
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}

const styles = {
  itemContainer: {
    margin: 7,
    fontSize: 15
  },
  itemValue: {
    fontWeight: 'bold',
  },
  itemContainerInfos: {
    margin: 5,
    fontSize: 15,
    background: "#ececec",
    padding: 7,
  },
}

export default UpdateForm