import React, { useState } from 'react'
import {connect} from "react-redux";

import makeStyles from '@material-ui/styles/makeStyles';
import { Link, Redirect, useLocation } from "react-router-dom";
import { Form, Input, Button, Checkbox, Card, Alert } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { signInUser } from 'services/auth'
import { signIn } from 'redux/actions'
import { logo } from 'assets/img/public'
import { logo2 } from 'assets/img/public'

import { USERS_ROLE } from 'constant';

import styles from './styles'

const useStyles = makeStyles(styles)

const initialState = {
  isLoading: false,
  alert: {isOpen: false, message: ''}
};

function Login({isAuthenticated, signIn}){
  const [state, setState] = useState(initialState)
  const classes = useStyles()

  const onFinish = values => {
    setState(state => ({...state, isLoading: true }));
    
    signInUser(values)
    .then(response => {
      const statusCode = parseInt(response.statusCode)

      if(statusCode === 10){

          signIn(response.accessToken, response.username)
          // window.location.reload()
          window.location.href="/"
      }
      else if(statusCode === 21) {
        setState(state => ({
          ...state, 
          isLoading: false,
          alert: {isOpen: true, message: 'Nom utilisateur ou Mot de passe incorrect. Veuillez réessayer SVP'} 
        }));
      }
    }).catch(error => {
      const statusCode = parseInt(error.statusCode)

      if(statusCode === 21) {
        setState(state => ({
          ...state, 
          isLoading: false,
          alert: {isOpen: true, message: 'Nom utilisateur ou Mot de passe incorrect. Veuillez réessayer SVP'}
        }));
      }                                          
      
    })
  };

  const location = useLocation()

  const {from} = location.state || {from: {pathname: "/"}};

  if(isAuthenticated){
    return <Redirect to={from}/>;
  } 

  return (
    <div className={classes.root} >
      <Card className={classes.formCard} >
        
        <div className={classes.logoContainer}>
          <img src={logo} alt="logo" className={classes.logo}/>
        </div>

        <div className={classes.title}>
        {/* <i className="fa fa-box"></i> 
        <img src={logo2} alt="logo" className={classes.logo}/>*/} ASACI - Connexion
        </div>

        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username" 
            rules={[
              {
                required: true,
                message: 'SVP renseignez votre email ou nom utilisateur!',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email ou nom utilsateur" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'SVP renseignez votre mot de passe!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Mot de passe"
            />
          </Form.Item>
          {/*<Form.Item>
             <Form.Item name="remember" valuePropName="checked" noStyle>
              <Checkbox>Remember me</Checkbox>
            </Form.Item> 

            <Link className="login-form-forgot" to="/newUser">
              S'inscrire
            </Link
          </Form.Item>>*/}<br/><br/>

          <Form.Item className={classes.loginBtn}>
            <Button 
              type="primary" 
              size="large" 
              htmlType="submit" 
              className="login-form-button"
              loading={state.isLoading}
            >
              Connexion
            </Button>
          </Form.Item>
        </Form>

        { state.alert.isOpen && <Alert message={state.alert.message} type="error" showIcon /> }
       
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps, {signIn})(Login)